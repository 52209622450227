<template>

  <div class="wrapper">

    <Slider></Slider>

    <Boosted></Boosted>

    <hr>

    <div class="buttons-sec ">

      <div class="dropdown">
        <button id="sports-btn" class="btn btn-secondary  dropdown-toggle ddb" type="button" data-toggle="collapse" data-target="#collapseSports" aria-expanded="false" aria-controls="collapseExample">
          Sports
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        </div>
      </div>

      <div class="dropdown">
        <button id="countries-btn" class="btn btn-secondary  dropdown-toggle ddb" type="button" data-toggle="collapse" data-target="#collapseCountries" aria-expanded="false" aria-controls="collapseExample">
          Countries
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

        </div>
      </div>

      <div class="dropdown">
        <button id="leagues-btn" class="btn btn-secondary  dropdown-toggle ddb" type="button" data-toggle="collapse" data-target="#collapseLeagues" aria-expanded="false" aria-controls="collapseExample">
          Leagues
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

        </div>
      </div>

      <div class="dropdown">
        <button id="market-btn" class="btn btn-secondary  dropdown-toggle ddb" type="button" data-toggle="collapse" data-target="#collapseMarkets" aria-expanded="false" aria-controls="collapseExample">
          Markets
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"></div>
      </div>

    </div>

    <div id="accordion">
      <Sports></Sports>
      <Countries></Countries>
      <Markets></Markets>
      <Leagues></Leagues>
    </div>

    <div class="highlight-box px-4 pt-3">
      <section class="bets-menu d-flex justify-content-between mt-1 mb-1 ">

        <div v-if="highlights" class="w-20 p-1 px-3 active">
          Highlights
        </div>
        <div v-else class="w-20 p-1 px-3 text-blue" @click="setHighlight">
          <a class="text-blue" style="text-decoration: none;">Highlights</a>
        </div>

        <div v-if="today" class="w-20 p-1 px-3 active">
          Today
        </div>
        <div v-else class="w-20 p-1 px-3 text-blue" @click="setToday">
          <a class="text-blue" style="text-decoration: none;">Today</a>
        </div>


        <div v-if="upcoming" class="w-20 p-1 px-3 active">
          Upcoming
        </div>
        <div v-else class="w-20 p-1 px-3 text-blue" @click="setUpcoming">
          <a class="text-blue" style="text-decoration: none;">Upcoming</a>
        </div>

        <div class="w-20 p-1 px-3 text-blue d-none">
          Chap! Pick
        </div>

      </section>
    </div>

    <hr>

    <Games v-bind:highlights="highlights" v-bind:today="today" v-bind:upcoming="upcoming" v-bind:sport="sport_id"></Games>

    <BottomNavigation></BottomNavigation>

  </div>

</template>

<script>
  const Slider = () => import('./Slider.vue')
  const Boosted = () => import('./Boosted.vue')
  const Markets = () => import('./accordion/markets.vue')
  const Sports = () => import('./accordion/sports.vue')
  const Countries = () => import('./accordion/countries.vue')
  const Leagues = () => import('./accordion/leagues.vue')
  const Games = () => import('./Games.vue')
  const BottomNavigation = () => import('./BottomNavigation.vue')
  import axios from "@/services/api";

  export default {
    name: 'Share',
    components: {
      Leagues,
      Countries,
      Boosted,
      Markets,
      Sports,
      Slider,
      Games,
      BottomNavigation
    },
    data: function (){
      return {
        highlights: true,
        upcoming: false,
        today: false,
        sub_page: '',
        mqttClient:'',
      }
    },
    mounted() {

      this.$store.dispatch("setCurrentPage","home");
      //this.$store.dispatch("setSportID",1);
      this.$store.dispatch("setHour",-1);
      //this.$store.dispatch("setMarketID",1);
      this.$store.dispatch("setCompetitionID",0);

      // reset game filter
      //this.$store.dispatch("resetAllGames");
      this.reloadProfile();

      this.booking_code = this.$route.params.code;
      console.log('got booking code '+this.booking_code);
      this.clearBetSlip();
      this.getPicks();

      if(this.booking_code.length > 0 ) {

        this.setValue('utm_source',this.booking_code)
        this.setValue('utm_campaign','share_bet')

      }

      var vm = this;

      this.EventBus.$on('share:code',function(code){

        vm.booking_code = code;
        console.log('got booking code '+vm.booking_code);
        vm.clearBetSlip();
        vm.getPicks();
      });

    },
    computed: {
      betslip_count: function (){

        return this.$store.state.betslip.total

      },
      current_sub_page: function () {

        return this.$store.state.current_sub_page;
      },
      sport_id: function () {

        return this.$store.state.sport_id;

      }
    },
    methods: {
      getPicks: function () {

        var vm = this;
        var path = process.env.VUE_APP_URL_RETRIEVE_CODE;

        axios.post(path, JSON.stringify({code: this.booking_code}))
                .then(res => {

                  var results = res.data.message;

                  if(results.length === 0 ) {

                    vm.setError("Invalid code","You have entered invalid or expired booking code")

                  } else {

                    vm.jQuery.each(results, function (k, v) {

                      var sport_id = v.sport_id;
                      var match_id = v.match_id;
                      var market_name = v.market_name;
                      var market_id = v.market_id;
                      var away_team = v.away_team;
                      var home_team = v.home_team;
                      vm.addPick(sport_id, match_id, market_name, market_id, home_team, away_team, v, v.status);
                    })

                    vm.setValue("booking_code", vm.booking_code);
                    vm.setValue("stake", 200);

                    vm.EventBus.$emit('event:betslip:show');

                  }

                })
                .catch(err => {

                  if (err.response) {

                    vm.setError("Failed", err.response.data.message)
                    console.log(JSON.stringify(err.response.data.message));

                  } else if (err.request) {

                    console.log(JSON.stringify(err.request));

                  } else {

                    console.log(JSON.stringify(err));

                  }

                })
      },

      setUpcoming: function(){

        this.highlights = false;
        this.today = false;
        this.upcoming = true;
      },
      setToday: function(){

        this.highlights = false;
        this.today = true;
        this.upcoming = false;
      },
      setHighlight: function(){

        this.highlights = true;
        this.today = false;
        this.upcoming = false;
      },
      setSubPage: function (page){

        this.$store.dispatch("setCurrentSubPage",page);
        this.sub_page = page;

      },
      isHighlight: function(){

        return this.highlights
      },
      isToday: function(){

        return this.today
      },
      isUpcoming: function(){

        return this.upcoming
      },

      geSubPageActiveClass: function (subpage) {

        return subpage === this.sub_page ? 'active' : "";

      },
      setSport: function(sportID) {

        this.$store.dispatch("setSportID",sportID);

      }
    },
  }

</script>