<template>
  <div class="mt-1">
    <div class="parent">
      <div class="banner d-flex">
        <a class="btn btn-link btn-sm" style="color: #ffffff" @click="goBack()">
          <svg fill="#fff" class="material-design-icon__svg" width="15" height="15" viewBox="0 0 24 24">
            <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z">
            </path>
          </svg>
          <span style="color:black; font-weight: bold;"> Go Back</span>
        </a>
      </div>
      <iframe allow="fullscreen" v-show="isLoaded" class="iframe-container" v-bind:src="launchURL" allowfullscreen
        webkitallowfullscreen></iframe>
    </div>
  </div>
</template>


<script>

import launch from "@/services/launch";

// import prag from "@/services/prag";
// import stp from "@/services/stp";
// import elbet from "@/services/elbet";
// import smart from "@/services/smart";
// import jetsafi from "@/services/jetsafi";
// import spribe from "@/services/spribe";
// import booming from "@/services/booming";
// import shacksevo from "@/services/shacksevo";
// import bgaming from "@/services/bgaming";
import walletserve from "@/services/walletserve";

export default {
  name: "CasinoGames",
  components: {
  },
  data: function () {
    return {
      loading: "",
      gameName: "",
      gameID: '',
      icon: "",
      isLoaded: false,
      launchURL: "",
      launchParameters: {},
      launchType: 1,
      isDemo: 0,

      amount: "",
    };

  },
  mounted() {

    this.$store.dispatch("setCurrentPage", "casinogames");
    this.reloadProfile();
    this.getLaunchUrl();

  },
  computed: {

    app: function () {
      return this.$store.state.app;
    },


    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },
  watch: {
  $route: {
    immediate: true,
    handler(to) {
      console.log('Route parameters:', to.params); // Add this to debug
      this.gameName = to.params.gameName;
      this.gameID = to.params.gameID;
      this.launchParameters = to.params.launchParameters;
      this.launchType = to.params.launchType;
      this.isDemo = to.params.IsDemo;
      let demoMode = "0";
        console.log("is demo " + to.params.IsDemo);

        if (to.params.IsDemo === 1) {
          demoMode = "1";
        }

      let payload = {
        provider_id: to.params.providerID,
        game_name: to.params.gameName,
        game_id: to.params.gameID,
        // is_demo: to.params.isDemo,
        is_demo: demoMode,

      };
      if (parseInt(to.params.providerID) > 0 && to.params.gameID.length > 0) {
          console.log(
            "setLaunchGamePayload " + JSON.stringify(payload, undefined, 2)
          );

          // either save in vuex
          this.$store.dispatch("setLaunchGamePayload", payload);

          // or save in session
          this.saveObject("launchGamePayload", payload);
        }
      this.getLaunchUrl();
    },
  },
},
  methods: {

    setAmount: function (amountToAdd) {
      // Convert the current amount to a number
      let currentAmount = parseFloat(this.amount);

      // Check if the current amount is a valid number
      if (isNaN(currentAmount)) {
        currentAmount = 0; // Set it to 0 if it's not a valid number
      }

      // Add the new amount to the existing amount
      currentAmount += amountToAdd;

      // Update the amount with the new total
      this.amount = currentAmount.toFixed(2); // Limit to 2 decimal places
    },

    deposit: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 10) {
        this.setError("Invalid Amount", "Enter amount atleast 10 KSH or above");
        return;
      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      vm.loading = "loading";
      console.log(this.getAuth());

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          vm.setSuccess("Success", msg);
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.error_message);
              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   "Network Error",
            //   "Check your network connection and try again three"
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    getLaunchUrl() {
      let data = this.$store.state.launchGamePayload;

      console.log('Launch ' + JSON.stringify(data, undefined, 2))

      if (parseInt(data.is_demo) === 0 && !this.profile) {

        this.setError("Login", "Please login to proceed");
        this.setValue("placeBet", 3);
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var providerId = data.provider_id;
      var gameId = data.game_id;
      // var gameName = data.game_name;
      // var accountID = parseInt(process.env.VUE_APP_CASINO_ACCOUNT_ID);

      var device_type = this.isMobile() ? "mobile" : "desktop";

      let payload = {};

      payload = {
          // account_id: accountID,
          device: String(device_type),
          game_id: String(gameId),
          demo: 0,
          // game_name: gameName,
          // lobby_url: window.location.origin + '/',
          return_url: window.location.origin + '/',
          provider_id: providerId,
        };

      // Determine which endpoint and payload to use based on provider ID
     

      console.log('provider ' + providerId + ' | payload ' + JSON.stringify(payload, undefined, 2))

      // Make API request with appropriate endpoint and payload
      // store.dispatch('updateGameInfo', payload);
      return this.launchGame(payload); // Return the launch URL


    },

    launchGame: function (payload) {

      var vm = this;
      // var providerId = payload.providerId;
      // var providerId = payload.providerId;

      // var service;

      // switch (providerId) {
      //   case 1:
      //     service = prag;
      //     break;
      //   case 3:
      //     service = stp;
      //     break;
      //   case 4:
      //     service = elbet;
      //     break;
      //   case 5:
      //     service = smart;
      //     break;

      //   case 6:
      //     service = jetsafi;
      //     break;

      //   case 7:
      //     service = spribe;
      //     break;

      //   case 8:
      //     service = booming;
      //     break;

      //   case 9:
      //     service = shacksevo;
      //     break;

      //   case 13:
      //     service = bgaming;
      //     break;

      //   default:
      //     console.error("Invalid providerId:", providerId);
      //     return; // Exit the function if providerId is invalid
      // }

      launch
        .post("/launch/url", payload, {
          headers: {
            "api-key": vm.getAuth(),
          }
        })
        .then(response => {

          vm.launchURL = response.data.game_url;
          console.log("LaunchURL", vm.launchURL)

          if (vm.launchType === "3") {

            window.open(this.launchURL);

          }

          console.log("LaunchUrl", vm.launchURL)
          this.isLoaded = true; // Set isLoaded to true after getting the launch URL
          return vm.launchURL;

        })
        .catch(error => {
          console.error("Error launching game:", error);
        });
    },

    goToCasino: function () {

      this.$router.push({ name: "casino", params: {} });

    },

    showDeposit: function () {

      document.getElementById("deposit-init").click();

    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },

  },
};
</script>

<style scoped>
.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);

}

.list-active {
  background-color: #F08B05;
}

.title_name {
  color: #fff;
}

.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.parent .banner {
  width: 100%;
  /* height: 48px; */
}

.parent iframe {
  background: #000;
  border: none;
  flex-grow: 1;
}

h4 {
  position: relative;
  top: 0.6em;
  right: 1em;
}
</style>