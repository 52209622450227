<template>
  <div>
    <div class="game-card px-2 mb-1">
      <div class="row p-0 m-0 small-text mb-0 opacity-8">
        <div style="color: #666666" class="col-9 p-0 m-0">
          {{ currentFixture.country }} •
          {{ shortenName(currentFixture.tournament, 40) }}
        </div>

        <div style="color: #666666" class="col-3 p-0 m-0 d-none">
          <span v-show="!live">{{ formatMatchDate(currentFixture.date) }}</span>
        </div>

        <div
          style="color: #666666"
          v-show="!live"
          class="col-3 p-0 m-0 text-right"
        >
          <span v-show="!live">{{ formatMatchDate(currentFixture.date) }}</span>
        </div>

        <div
          style="color: #666666"
          v-show="live"
          class="col-3 p-0 m-0 text-right"
        >
          {{ currentFixture.match_status }}
          <span class="text-yellow pl-1">
            {{ currentFixture.event_time }}
          </span>
        </div>
      </div>

      <div class="row p-1 m-0 games">
        <div class="col-4 px-0 text-light game-card-teams pt-0">
          <router-link
            :to="{
              name: 'match',
              params: {
                game_id:
                  currentFixture.game_id === undefined
                    ? 0
                    : currentFixture.game_id,
                name: toURL(currentFixture.name),
                status: live ? 'live' : 'prematch',
              },
            }"
            class="text-light d-block font-weight-bold"
            style="text-decoration: none"
          >
            <div style="color: black; font-weight: normal" class="mb-1">
              <span
                v-show="live"
                class="score"
                v-text="currentFixture.home_score"
              ></span>
              {{ shortenName(home_team, 20) }}
            </div>

            <div style="color: black; font-weight: normal" class="mb-0">
              <span
                v-show="live"
                class="score"
                v-text="currentFixture.away_score"
              ></span>
              {{ shortenName(away_team, 20) }}
            </div>

            <div
              class="odd-indicator pt-0 font-weight-bold marketee skyBlue-text opacity-8"
              style="
                color: rgb(102, 102, 102);
                font-size: 8px;
                font-weight: normal;
                padding-top: 0.5em !important;
              "
            >
              +{{ currentFixture.total_markets }} <span class=""> markets</span>
            </div>
          </router-link>
        </div>
        <div class="col-8">
          <div class="row" style="align-content: center">
            <Odd
              style="
                margin: 0px !important;
                padding: 2px !important;
                height: fit-content;
              "
              v-for="o in currentFixture.outcomes"
              v-bind:key="getKey(o.odd_id)"
              v-bind:id="
                getOddID(
                  currentFixture.match_id,
                  o.market_id,
                  o.outcome_id,
                  o.specifier,
                  'odd'
                )
              "
              v-bind:class="getClasses(o, currentFixture.outcomes)"
              v-bind:sport_id="currentFixture.sport_id"
              v-bind:match_id="currentFixture.match_id"
              v-bind:market_name="o.market_name"
              v-bind:home_team="home_team"
              v-bind:away_team="away_team"
              v-bind:market_id="o.market_id"
              v-bind:outcome_id="o.outcome_id"
              v-bind:specifier="o.specifier"
              v-bind:active="o.active"
              v-bind:status="o.status"
              v-bind:odds="o.odd"
              v-bind:producer_id="o.producer_id"
              v-bind:producer_status="o.producer_status"
              v-bind:previous_odds="o.previous_odds"
              v-bind:alias="o.alias"
              v-bind:probability="o.probability"
              v-bind:pick="o"
              v-bind:live="live"
            />
          </div>
        </div>
        <div class="col-1 px-0 pt-4 text-center" style="display: none">
          <img
            class="img-src"
            src="img/icon/chart.png"
            height="10"
            width="10"
          />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Odd from "./Odd";

export default {
  components: {
    Odd,
  },
  name: "fixture",
  props: {
    fixture: {
      required: true,
    },
    live: {
      required: false,
      default: false,
    },
  },
  computed: {
    _hasMoreMarketsInBetslip: function () {
      if (
        this.$store.state.betslip.picks !== undefined &&
        this.$store.state.betslip.picks[this.currentFixture.match_id] !==
          undefined &&
        parseInt(
          this.$store.state.betslip.picks[this.currentFixture.match_id]
            .outcome_id
        ) > 3
      ) {
        console.log("Has More markers");
        return "yellow-text";
      } else {
        console.log("Has More markers");
        return "skyBlue-text";
      }
    },
    event_time: function () {
      var minutes = parseInt(this.seconds / 60);
      //var seconds = this.seconds % 60;

      var min = minutes;

      if (minutes < 10) {
        min = "0" + min;
      }

      return min + "'";
    },
    sport_name: function () {
      return this.getSportName(this.currentFixture.sport_id);
    },
    country_name: function () {
      return this.currentFixture.country;
    },
    home_team: function () {
      return this.getHomeCompetitorName(this.currentFixture.name);
    },
    away_team: function () {
      return this.getAwayCompetitorName(this.currentFixture.name);
    },
    sportIcon: function () {
      return this.getSportIcon(this.currentFixture.sport_id);
    },

    sportAlt: function () {
      switch (this.currentFixture.sport_id) {
        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/assets/icons/dart.svg";

        case 20:
          return "/assets/icons/tabletennis.svg";

        case 6:
          return "/assets/icons/handball.svg";

        case 12:
          return "/assets/icons/rugby.svg";

        case 10:
          return "/assets/icons/boxing.svg";

        case 31:
          return "/assets/icons/badminton.svg";

        case 3:
          return "/assets/icons/baseball.svg";
      }
      return "";
    },
    currentFixture: {
      // getter
      get: function () {
        return this.formattedFixture;
      },
      // setter
      set: function (newValue) {
        this.formattedFixture = newValue;
      },
    },
  },
  methods: {
    hasMoreMarketsInBetslip: function (match_id) {
      if (
        this.$store.state.betslip.picks !== undefined &&
        this.$store.state.betslip.picks[match_id] !== undefined &&
        parseInt(this.$store.state.betslip.picks[match_id].outcome_id) > 3
      ) {
        console.log("Has More markers");
        return true;
      }
    },
    stats: function () {
      var url =
        "https://s5.sir.sportradar.com/betkali/en/match/" +
        this.currentFixture.match_id;

      return window.open(url, "", "width=1000,height=1000");
    },
    isLocked: function (o) {
      if (parseInt(o.producer_id) === 1) {
        return (
          parseInt(o.status) > 0 ||
          parseInt(o.active) === 0 ||
          parseInt(o.producer_status) === 0
        );
      } else {
        return parseInt(o.status) > 0 || parseInt(o.active) === 0;
      }
    },

    getKey: function (index) {
      var prefix = "odds-" + index;
      return Math.random()
        .toString(10)
        .replace("0.", "odd-id-" + prefix + "-");
    },

    reloadUI: function () {
      var vm = this;

      console.log("reloading ui");

      var betsObject = this.getBetSlip(0);

      var bets = betsObject.picks;

      vm.jQuery(".picked").removeClass("picked");

      this.checkSlipCounter(betsObject.total);

      this.$store.dispatch("setBetSlip", betsObject);

      vm.jQuery.each(bets, function (matchID, b) {
        var matchClass = ".match-" + matchID;
        var oddsClass = "#" + b.odd_id;

        if (vm.jQuery(oddsClass).length) {
          console.log(" exists " + oddsClass);
        } else {
          console.log(" doesn't exists " + oddsClass);
        }

        vm.jQuery(matchClass).removeClass("picked");
        vm.jQuery(oddsClass).addClass("picked");
        vm.jQuery("#betslip-footer").removeClass("hidden");
      });
    },

    getV: function (x) {
      return x;
    },
    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }

      return matchName.split("vs.")[0];
    },
    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },
    getClasses: function (o, fixtures) {
      //var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? 'btn-odd' : 'btn-odd';
      var col = "col-4";

      if (fixtures.length == 2) {
        col = "col-6";
      }

      return (
        "" +
        " match-" +
        this.currentFixture.match_id +
        " producer-" +
        o.producer_id +
        " match-" +
        this.currentFixture.match_id +
        " market-" +
        o.market_id +
        " outcome-" +
        o.outcome_id +
        " specifier-" +
        o.specifier +
        " " +
        col
      );
    },
    matchURL: function () {
      var game_id = this.currentFixture.game_id;
      var name = this.toURL(this.currentFixture.name);

      var url = "/match/" + game_id + "/" + name;
      return url;
    },
    toURL: function (name) {
      if (name === undefined) {
        return "-";
      }

      return name.replace(/[^a-z0-9+]+/gi, "-");
    },
    getOddDirectionClass: function (outcome) {
      if (parseInt(outcome.active) === 0 || parseInt(outcome.status) != 0) {
        return "";
      }

      if (parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds)) {
        return "arrow green up";
      }

      return "arrow red down";
    },
  },
  mounted: function () {
    var vm = this;

    if (Array.isArray(this.fixture)) {
      this.currentFixture = this.fixture[0];
    } else {
      this.currentFixture = this.fixture;
    }

    // split to get event time as seconds
    if (this.currentFixture.event_time.length > 0) {
      var p = this.currentFixture.event_time.split(":");
      var minutes = p[0];
      var seconds = p[1];
      seconds = parseInt(seconds);
      seconds = seconds + parseInt(minutes) * 60;
      this.seconds = seconds;
    }

    this.isLive = this.live;

    if (this.currentFixture.match_status === "NotStarted") {
      this.upcoming = true;
      this.isLive = false;
      vm.to_start = vm.timeToStart(vm.currentFixture.date);
    }
  },
  data: function () {
    return {
      color: "",
      seconds: 0,
      to_start: "",
      formattedFixture: "",
      upcoming: "",
      isLive: "",
      odds: "",
      status: "",
      active: "",
    };
  },
};
</script>
