<template>
  <div class="wrapper">

     <div class="row">
        <div class="col-6">
          <div @click="setCountry('')">
            <div class="item-wrapper">
              <img v-bind:src="getCountryFlag('')" width="13" height="13">
              <span class="ml-2 item-wrapper-span-active">All Countries </span>
            </div>
          </div>
        </div>
        <div class="col-6" v-for="(c,index) in countries" v-bind:key="getCountryKey(c,index)">
          <div @click="setCountry(c)">
            <div class="item-wrapper">
              <img v-bind:src="getCountryFlag(c.country_code)" width="13" height="13">
              <span class="ml-2 item-wrapper-span-active">{{ c.country_name }} ({{ c.games }}) </span>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>

import axios from "../services/api";
import mqtt from "mqtt";

export default {
  name: 'Countries',
  components: {
  },
  data: function () {
    return {
      sub_page: '',
      loading: [],
      visible: [],
      is_busy: false,
      busy: false,
      countries: [],
      mqttClient: false,
      dates: [],
      sport_name: 'Sports',
      selected_hour: 0,
      hours: [-1, 1, 3, 6, 12, 24, 48],
      menu: 'all',
      selectedOption: 'A-Z Countries'
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage", "countries");

    this.getCountries();
    this.initMqtt();

  },
  computed: {
    sports: function () {
      return this.$store.state.sports
    },
    betslip_count: function () {

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
    market_id: function () {
      return this.$store.state.market_id;
    },
    hour: function () {

      return -1;
      // this.$store.state.hour;
    },
    date: function () {

      return this.$store.state.upcoming_date

    }
  },
  methods: {
        setCountry: function (country) {

      // document.getElementById("countries-btn").click();
      this.menu = 'country';

      if(country == '') {

        this.$store.dispatch("setCountryCode", "");

      } else {

        this.$store.dispatch("setCountryCode", country.country_code);

      }
       this.$router.push({ name: 'home', params: { } });
    },

    getMarketClass: function (menu) {

      return menu === this.menu ? 'market-list-active' : 'market-list';

    },

    getKey: function (index) {

      return Math.random().toString(20).replace('0.', 'random-id-' + index + '-');

    },

    setHour: function (h) {

      this.selected_hour = h;
      this.$store.dispatch("setHour", h);

    },
    humanHourName: function (h) {

      if (h === -1) {

        return 'All Time'
      }

      return h === '' || h === "" || h === 0 ? 'Time' : h + ' Hours'

    },
    initMqtt: function () {

      var vm = this;

      if (this.mqttClient !== false) {

        this.mqttClient.end();

      }

      var market_id = 1;
      var sport_id = this.sport_id === 0 ? 1 : this.sport_id;

      // subcribe to specific sportID (this.sport_id) and all matches but only specific match this.market_id odds changes
      var topic = 'topic/producer-3/sport-' + sport_id + '/+/market-' + market_id;

      var client = mqtt.connect(process.env.VUE_APP_URL_MQTT_HOST);

      client.on('connect', function () {
        client.subscribe(topic, function (err) {
          if (!err) {

            console.log('subscribed to topic ' + topic);

          }
        })
      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.oddsUpdate(payload);
      })

      this.mqttClient = client;

    },

    getCountries: function () {

      var vm = this;
      var sport_id = this.$store.state.sport_id;
      var path = process.env.VUE_APP_URL_MENU.replace("{sport_id}", sport_id);

      vm.busy = true;
      axios.post(path, JSON.stringify({
        hours: vm.hour,
        date: vm.date,
      }))
          .then(res => {

            vm.busy = false;

            vm.countries = res.data.message.country;

          })
          .catch(error => {

            console.log(error)

          })
    },
    isLoading: function (index) {

      const i = this.loading.indexOf(index);
      if (i > -1) {
        return true;

      }

      return false;
    },
    selectedClass: function (index) {

      const i = this.visible.indexOf(index);
      if (i > -1) {
        return 'selected';
      }

      return '';
    },
    isVisisble: function (index) {

      const i = this.visible.indexOf(index);
      if (i > -1) {

        return true;

      }
      return false;

    },
    loadData: function (index) {

      const i = this.loading.indexOf(index);

      if (i > -1) {  // index already exisits in loading array

        // toggle visibility
        const i = this.visible.indexOf(index);

        // check if its visible
        if (i > -1) {

          this.visible.splice(i, 1); // hide visibility

        } else {

          this.visible.push(index); // else make visible

        }

      } else {

        this.loading.push(index);
        // make visible
        this.visible.push(index);
      }

    },
    getCountryKey: function (league, index) {

      var prefix = league === undefined || league.sport_id == undefined ? index : league.sport_id;
      return Math.random().toString(10).replace('0.', 'sport-id-' + prefix + '-');

    },
    setSport: function (sport) {

      this.menu = 'sport';
      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.selectedOption = this.sport_name;
      this.$store.dispatch("setSportID", sport.sport_id);
      this.$store.dispatch("setSportName", sport.sport_name);
    },
    geChildPageActiveClass: function (childpage) {

      return childpage === this.$store.state.upcoming_date ? 'selected-date' : "";

    },
    setUpcomingDate: function (date,index,label) {

      this.menu = date;
      this.$store.dispatch("setUpcomingDate", date)
      this.selectedOption = this.getDayName(index, label)+'s Matches';

    },
    setUpcomingAll: function () {

      this.menu = 'all';
      this.$store.dispatch("setUpcomingDate", '');
      this.selectedOption = 'Upcoming Matches';
    },
    getDayName: function (i, label) {

      console.log('index ==> ' + i + ' label ==> ' + label);

      if (i === 0) {

        return 'Today';
      }

      if (i === 1) {

        return 'Tomorrow';

      }

      return label;

    },
  },
  watch: {
    sport_id: function (newValue, oldValue) {

      if (parseInt(oldValue) > 0) {

        this.getCountries();
        this.initMqtt();

      }

    },

    hour: function (newValue, oldValue) {

      if (parseInt(newValue) !== parseInt(oldValue)) {

        return;

      }

      console.log('Hour =>newValue ==> ' + newValue + ' oldValue ==> ' + oldValue);
      this.is_busy = true;
      this.getCountries();
    },

    date: function (newValue, oldValue) {

      console.log('date newValue ==> ' + newValue + ' oldValue ==> ' + oldValue);
      this.getCountries();

    },

    competition_id: function (newValue, oldValue) {

      if (parseInt(newValue) !== parseInt(oldValue)) {

        return;

      }

      this.competitions = [];

      if (this.highlights) {

        this.per_page = 20;
        this.page = 0;
        this.last_page = 0;
        this.remaining_records = 0;
        this.getHighlight();

      } else {

        if (this.visible_leagues.length > 0) {

          this.getFixture(this.visible_leagues.join(','));

        }

      }

    },

  },
  beforeDestroy: function () {
    console.log("beforeDestroy")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }
  }
}
</script>