import jQuery from "jquery";
import Vue from "vue";

const $ = jQuery;
Vue.prototype.jQuery = $;

const betslip = {
    created: function () {

        var vm = this;

        // checkSuspendedBetSlip
        setInterval(function () {

            vm.checkSuspendedBetSlip();
            vm.checkOddsChanges();

        }, 500);

    },
    methods: {

        /**
         *
         * @param sport_id
         * @param match_id
         * @param market_name
         * @param market_id
         * @param competitor_1
         * @param competitor_2
         * @param picks
         */
        addPick: function (sport_id,match_id,market_name,market_id,competitor_1,competitor_2,producer_id,specifier,picks,status) {

            var producer_status = picks.producer_status;
            picks.status = status;
            var active = picks.active;

            if (parseInt(producer_id) === 1 && parseInt(producer_status) === 0) {
                console.log(
                    "exiting due to producer_id#" +
                    producer_id +
                    " producer_status#" +
                    producer_status
                );
                return;
            }

            if (parseInt(active) === 0) {
                console.log("exiting due to active#" + active + " status#" + status);
                return;
            }

            if (parseInt(status) !== 0) {
                console.log("exiting due to deactivated market#" + active + " status#" + status);
                return;
            }

            var betslip = this.getBetSlip()

            var bSlip = betslip.picks;

            var isunselect = false;
            var isNewMatchID = true;

            // create picks
            picks.match_id = match_id;
            picks.sport_id = sport_id;
            picks.market_name = market_name;
            picks.market_id = market_id;
            picks.competitor_1 = competitor_1;
            picks.competitor_2 = competitor_2;
            picks.producer_id = producer_id;
            picks.specifier = specifier;
            picks.id = this.getOddID(match_id, market_id,picks.outcome_id,picks.specifier,"odd");
            picks.id2 = this.getOddID(match_id, market_id,picks.outcome_id,picks.specifier,"boosted");

            // check max selection
            if (bSlip.length > 29) {

                // this.setError("Error", "Maximum number of games reached");
                // return;

            }

            var newSlips = []

            // loop through each slip
            $.each(bSlip, function (k, v) {

                let deleteItem = false

                // if selection had already been selected we make it to be unselected, we remove it from the picks
                if (v.id === picks.id) {

                    deleteItem = true;
                    isunselect = true;

                }


                // if match had already been selected, we remove it to be replaced with the new selection
                if (v.match_id === match_id) {

                    isNewMatchID = false
                    deleteItem = true
                }

                if(!deleteItem) {

                    newSlips.push(v)
                }

            });

            if (!isunselect) {

                newSlips.push(picks);

            }

            betslip.picks = newSlips;

            this.setBetSlip(betslip)

            if(isNewMatchID) {

                this.EventBus.$emit(`subscribe:websocket`, {matchID: match_id});

            }

            this.autoRefreshUI("addPick");

        },

        /*
        This function is called wherever we receive odds change from websockets
         */
        updateOdd: function (match_id,market_id,outcome_id,specifier, odds, active, status) {

            var betslip = this.getBetSlip()

            var bSlip = betslip.picks

            var bt = [];

            // get pick id
            var id = this.getOddID(match_id,market_id,outcome_id,specifier,"odd");

            $.each(bSlip, function (k, v) {

                if (v.id === id) {

                    console.log('odds changed updateOdd  '+id+' new status '+status)

                    var hasChanged = false;
                    let previous_odds = v.odd
                    v.odd = odds;
                    v.odds = odds;
                    var changeDetails = ""

                    if (parseInt(v.active) !== parseInt(active)) {

                        hasChanged = true;

                    } else if (parseInt(v.status) !== parseInt(status)) {

                        hasChanged = true;

                    }

                    if ( parseInt(active) !== 1 ) {

                        changeDetails = "Your selection has been deactivated"

                    } else if ( parseInt(status) !== 0 ) {

                        changeDetails = "Selected market has been suspended"

                    }

                    if(parseFloat(previous_odds) !== parseFloat(odds)) {

                        hasChanged = true;
                        changeDetails = `Odds changed from ${previous_odds} to ${odds} `

                    }

                    v.active = active;
                    v.status = status;
                    v.details = changeDetails;
                    v.odds_change = hasChanged;
                    v.previous_odds = previous_odds;

                }

                bt.push(v);

            });

            betslip.picks = bt

            this.setBetSlip(betslip)

            this.autoRefreshUI("updateOdd");

        },

        removePick: function (match_id) {

            var betslip = this.getBetSlip()

            var bSlip = betslip.picks

            var newSlips = []

            this.jQuery.each(bSlip, function (k, v) {

                    if (v !== null && v !== undefined) {

                        if (parseInt(v.match_id) !== parseInt(match_id) ) {

                            newSlips.push(v)
                        }
                    }

            });

            betslip.picks = newSlips;

            this.setBetSlip(betslip)

            this.autoRefreshUI("removePick");

            if (bSlip.length === 0) {

                $(".faso-close").click();

            }

        },

        /**
         * clears a  betslip
         */
        clearBetSlip: function () {

            var betslip = this.getBetSlip()
            betslip.picks = [];
            this.setBetSlip(betslip)
            this.autoRefreshUI("clearBetSlip");
            this.EventBus.$emit("event:betslip:hide");
            this.$store.commit("setOddsChanged",false)
            this.$store.commit("setHasSuspendedPicks",false)

        },

        computeWinnings: function () {

            var betslip = this.getBetSlip();

            var bSlip = betslip.picks

            var total = 0;

            var stake = this.getStake();

            var totalOdds = parseFloat(1);

            if(bSlip.length > 0 ) {

                this.jQuery.each(bSlip, function (k, v) {

                    if (v !== null && v !== undefined) {

                        var odds = parseFloat(v.odds);
                        totalOdds = totalOdds * odds;
                        total++

                    }


                });

            }

            // get total odds

            stake = parseFloat(stake)

            if (isNaN(stake) ){

                stake = parseFloat("50");

            }

            var taxOnStake = parseFloat("0"); //12.5;
            var taxOnWinning = parseFloat("0"); //20;

            var wagerTax = (stake * taxOnStake) / 100;
            if (isNaN(wagerTax) ){

                wagerTax = parseFloat("0");

            }

            // var stake = stakeBeforeTax / 1.2;
            var stakeAftertax = stake - wagerTax;

            var winning = stakeAftertax * totalOdds;

            if (winning > 1000500) {

                winning = 1000500;

            }

            var netWinning = winning - stakeAftertax;

            var withholdingTax = netWinning * taxOnWinning / 100;

            var payout = winning - withholdingTax;

            betslip.payout = payout;
            betslip.stake = stake;
            betslip.wager_tax = wagerTax;
            betslip.stake_after_tax = stakeAftertax;
            betslip.winning = winning;
            betslip.tax = withholdingTax;
            betslip.total = total;
            betslip.odds = parseFloat(totalOdds).toFixed(3);
            this.setBetSlip(betslip)

        },

        /*
        resets all odds change notifications from betslip
         */
        clearOddsChange: function () {

            var betslip = this.getBetSlip()

            var bSlip = betslip.picks

            $.each(bSlip, function (k, v) {

                v.details = "";
                v.odds_change = false;
                bSlip[k] = v;

            });

            betslip.picks = bSlip

            this.setBetSlip(betslip)

            this.$store.commit("setOddsChanged",false)

            this.autoRefreshUI("updateOdd");

        },

        /**
         * returns betslip object from store
         */
        getBetSlip: function () {

            var betslip = this.$store.state.betslip

            // if betslip object has no picks, initialize empty picks
            if(betslip === undefined  ) {

                betslip = {};
                betslip.picks = [];

            }

            /*
            var bSlip = []

            this.jQuery.each(betslip.picks, function (k, v) {

                if (v !== null && v !== undefined) {

                    bSlip.push(v);
                }

            });

            betslip.picks = bSlip;
            */

            return betslip
        },

        getStake: function () {

            var stake = this.getValue("stake")

            if (stake === undefined || isNaN(stake)) {

                stake = "50"

            }

            stake = parseFloat(stake)

            if (stake === 0 ) {

                stake = 50
            }

            return stake;

        },

        setBetSlip: function (betslip) {

            this.$store.dispatch("setBetSlip",betslip)

        },

        checkSuspendedBetSlip: function () {

            var betslip = this.getBetSlip()

            var bSlip = betslip.picks

            var expiredOdds = false;

            if(bSlip.length > 0 ) {

                this.jQuery.each(bSlip, function (k, v) {

                    if (v !== null && v !== undefined) {

                        if (parseInt(v.status) !== 0 || parseInt(v.active) !== 1) {

                            expiredOdds = true;
                        }

                    }

                });

            }

            this.$store.commit("setHasSuspendedPicks",expiredOdds)

        },

        checkOddsChanges: function () {

            var betslip = this.getBetSlip()

            var bSlip = betslip.picks

            var oddsChanged = false;

            if(bSlip.length > 0 ) {

                this.jQuery.each(bSlip, function (k, v) {

                    if (v !== null && v !== undefined) {

                        if (v.odds_change === true ) {

                            oddsChanged = true;
                        }
                    }


                });

            }

            this.$store.commit("setOddsChanged",oddsChanged)

        },

        removeSuspendedPicks: function () {

            var betslip = this.getBetSlip()

            var bSlip = betslip.picks

            var bt = [];

            $.each(bSlip, function (k, v) {

                if (v !== undefined && v !== null) {

                    //a suspended/deactivated outcome has active = 0 and status > 0
                    if (parseInt(v.active) === 1 && parseInt(v.status) === 0) {

                        bt.push(v);

                    }

                }
            });

            betslip.picks = bt

            this.setBetSlip(betslip)

            this.$store.commit("setHasSuspendedPicks",false)

            this.autoRefreshUI("removeSuspendedPicks");

        },

        autoRefreshUI: function () {

            this.computeWinnings()

            var vm = this;
            var betsObject = this.getBetSlip();
            var bets = betsObject.picks;

            // vm.jQuery(".picked").removeClass("picked");

            $.each(bets, function (k, b) {

                    if (b !== null && b !== undefined) {

                        vm.jQuery("#boosted-" + b.id).addClass("picked");
                        vm.jQuery("#odds-" + b.id).addClass("picked");
                    }

            });

            this.checkSlipCounter(betsObject.picks.length);

            this.EventBus.$emit("reload:betslip");

        },

        isPicked: function (id) {

            var betslip = this.getBetSlip()

            var bSlip = betslip.picks

            var selected = false;

            if(bSlip.length > 0 ) {

                this.jQuery.each(bSlip, function (k, v) {

                    if (v !== null && v !== undefined) {

                        if (v.id === id ) {

                            selected = true;
                        }

                    }

                });

            }

            return selected

        },

    }
}
//- export it as a plugin
export default {
    install (Vue) {
        Vue.mixin(betslip)
    }
}