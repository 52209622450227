<template>
  <div style="min-height: 100vh;">
    <div class="container">

      <div class="timeline-wrapper" v-show="is_busy">

        <div class="timeline-item">

          <!-- <Waiting /> -->

        </div>

      </div>

    </div>

    <Competition
        v-show="!highlights"
        v-for="(l,index) in competitions"
        v-bind:key="getLeagueKey(l,index)"
        v-bind:competition="l"
        v-bind:is_visible="true"
        v-bind:upcoming="upcoming"
        v-bind:today="today"
        v-bind:esport="esport"
        v-bind:highlights="highlights"
        v-bind:live="live"
        v-bind:leo="leo"
        v-bind:date="date">
    </Competition>

  </div>
</template>

<script>
// const Highlight = () => import("./Highlight.vue");
import Competition from './Competition'
// import Waiting from "@/components/Waiting.vue";

//import infiniteScroll from 'vue-infinite-scroll'
import axios from "@/services/api";
import mqtt from "mqtt/dist/mqtt";
import fix from "@/services/fix";

//Vue.use(infiniteScroll)

export default {
  name: "Alllive",
  mounted: function () {
    // this.fetchFixtures();
    this.reloadProfile();
    this.getBonus();
    var vm = this;

    this.is_busy = true;
    vm.visible_leagues = [];

    // keep a list of openned/expanded leagues
    this.EventBus.$on("event:leagues:show", function (id) {
      var exists = false;
      vm.jQuery.each(vm.visible_leagues, function (k, v) {
        if (parseInt(v) === parseInt(id)) {
          exists = true;
        }
      });

      if (!exists) {
        vm.visible_leagues.push(parseInt(id));
      }
    });

    this.initMqtt(this.sport_id);

    this.$nextTick(function () {
      vm.autoRefreshUI(this.$vnode.tag);
    });

    //setInterval(function (){

    if (vm.searchable) {
      if (vm.search.length > 0) {
        vm.getSearch();
      } else {
        vm.is_busy = false;
      }
    } else if (vm.live) {
      vm.getAlllive();
    } else if (vm.esport) {
      vm.getEsport();
    } else if (vm.highlights) {
      vm.getHighlight();
    } else if (vm.today) {
      vm.getTodayFixtures();
    } else if (vm.leo) {
      vm.getAlllive();
    } else {
      vm.getCompetitions();
    }

    //},1000 * 30);
  },
  methods: {
    registerPoll: function () {
      var market_id = this.market_id == 0 ? 1 : this.market_id;
      var sport_id = this.sport_id == 0 ? 1 : this.sport_id;

      // subcribe to specific sportID (this.sport_id) and all matches but only specific match this.market_id odds changes
      var topic_betstop =
          "topic/producer-3/sport-" + sport_id + "/status/+/market-" + market_id;

      if (this.live) {
        topic_betstop =
            "topic/producer-1/sport-" +
            sport_id +
            "/status/+/market-" +
            market_id;
      }

      this.poll(topic_betstop);
    },

    initMqtt: function () {
      var vm = this;

      if (this.mqttClient !== false) {
        this.mqttClient.end();
      }

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      };

      var market_id = this.market_id == 0 ? 1 : this.market_id;
      var sport_id = this.sport_id === 0 ? 1 : this.sport_id;

      var topic_match = "topic/producer-3/sport-" + sport_id + "/match/+";

      // subcribe to specific sportID (this.sport_id) and all matches but only specific match this.market_id odds changes
      var topic_market =
          "topic/producer-3/sport-" + sport_id + "/status/+/market-" + market_id;
      var topic_betstop = "topic/producer-3/sport-" + sport_id + "/betstop/+";

      let endpoint = process.env.VUE_APP_URL_MQTT_HOST;

      var client = mqtt.connect(endpoint, options);

      client.on("connect", function () {
        client.subscribe(topic_betstop, function (err) {
          if (!err) {
            // console.log("subscribed to topic " + topic_betstop);
          }
        });

        client.subscribe(topic_market, function (err) {
          if (!err) {
            // console.log("subscribed to topic " + topic_market);
          }
        });

        client.subscribe(topic_match, function (err) {
          if (!err) {
            // console.log("subscribed to topic " + topic_match);
          }
        });


        var topic2 = "topic/producer";
        client.subscribe(topic2, function (err) {
          if (!err) {
            // console.log("subscribed to topic " + topic2);
          }
        });
      });

      client.on("message", function (topic, msg) {

        var payload = JSON.parse(msg.toString(), undefined, 2);
        // console.log("Here is the payload:::::::::::", JSON.stringify(payload, undefined, 2));
        vm.uxUpdate(payload);
      });

      this.mqttClient = client;
    },

    getKey: function (fixture, index) {
      if (Array.isArray(fixture)) {
        var currentFixture = fixture[0];
      } else {
        currentFixture = fixture;
      }

      var prefix =
          currentFixture === undefined || currentFixture.match_id == undefined
              ? index
              : currentFixture.match_id;
      return Math.random()
          .toString(10)
          .replace("0.", "fixture-id-" + prefix + "-");
    },

    getLeagueKey: function (league, index) {
      var prefix =
          league === undefined || league.competition_id == undefined
              ? index
              : league.competition_id;
      return Math.random()
          .toString(10)
          .replace("0.", "competition-id-" + prefix + "-");
    },

    getCompetitions: function () {
      var vm = this;
      var endpoint = process.env.VUE_APP_URL_TOP_LEAGUES;

      if (this.live) {
        endpoint = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';
      }

      if (this.esport) {
        endpoint = process.env.VUE_APP_URL_ESPORT_TOP_LEAGUES;
      }

      var path = endpoint.replace(
          "{sport_id}",
          parseInt(vm.sport_id) > 0 ? vm.sport_id : 1
      );
      path = path.replace("{count}", 0);

      var games_filter = {};

      if (this.date.length > 0) {
        games_filter.date = this.date;
      }

      if (parseInt(this.upcoming) > 0) {
        games_filter.upcoming = this.upcoming;
        games_filter.highlights = "";
      }

      games_filter.hour = vm.hour; //default -1

      fix
          .post(path, JSON.stringify(games_filter))
          .then((res) => {
            var leagues = res.data.message;

            if (vm.highlight || vm.highlight) {
              vm.jQuery.each(leagues, function (k, v) {
                v.fixtures = [];
                v.is_busy = false;
                v.is_visible = false;
                v.has_fixture = false;

                var exist = false;

                // check if league exists in current leagues
                vm.jQuery.each(vm.leagues, function (kk, vv) {
                  if (vv.competition_id === v.competition_id) {
                    exist = true;
                  }
                });

                if (!exist) {
                  vm.leagues.push(v);
                }
              });
            } else {
              vm.leagues = leagues;
              vm.jQuery.each(vm.leagues, function (k, v) {
                v.fixtures = [];
                v.is_busy = false;
                v.is_visible = false;
                v.has_fixture = false;
                vm.leagues[k] = v;
              });
            }

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.getGames();
            vm.autoRefreshUI(vm.$vnode.tag);
          })
          .catch((error) => {
            console.log(error);
          });
    },

    getFixture: function (competitions) {
      var vm = this;

      var path = process.env.VUE_APP_URL_FIXTURES_COMPETITIONS;

      if (this.highlights) {
        path = process.env.VUE_APP_URL_HIGHLIGHTS;
      } else if (this.today) {
        path = process.env.VUE_APP_URL_TODAYS_COMPETITIONS;
      } else if (this.live) {
        path = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';
      }

      path = path.replace("{sport_id}", vm.sport_id);
      path = path.replace("{competition_id}", 0);
      path = path.replace("{market_id}", vm.market_id);
      path = path.replace("{page}", 1);
      var payload = {
        highlights: 1,
        upcoming: 0,
        hours: this.hour,
        country_code: "",
        date: "",
        keyword: "",
        per_page: 100,
        competitions: competitions,
      };

      if (this.date.length > 0) {
        payload.date = this.date;
      }

      if (parseInt(this.upcoming) > 0) {
        payload.upcoming = this.upcoming;
        payload.highlights = "";
      }
      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        match_live_status: 1,
      };

      fix
          .get(path, { params: dat })
          .then((res) => {
            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = res.data.message;

            vm.jQuery.each(vm.leagues, function (k, v) {
              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {
                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                  fx.push(vv);
                  vm.EventBus.$emit("event:leagues:show", v.competition_id);
                }
              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;
            });
            vm.competitions = vm.leagues;
            vm.autoRefreshUI(vm.$vnode.tag);
          })
          .catch((err) => {
            vm.busy = false;

            vm.loading = "";

            if (err.response) {
              vm.setError("Failed", err.response.data.message);
            } else if (err.request) {
              // console.log(JSON.stringify(err.request));
            } else {
              // console.log(JSON.stringify(err));
            }
          });
    },

    getTodayFixtures: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';

      path = path.replace("{sport_id}", this.sport);

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        tournament_id:0,
        category_id:0,
        daily:0,
        hours:0,
        match_live_status: 1,
        today:0,
        tomorrow:0,
        upcoming:0,
        boosted:0,
        order_type:'priority',
        
      };

      fix
          .get(path, { params: dat })
          .then((res) => {
            // console.log("Res", res)
            var games = res.data.data;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });
            vm.$store.dispatch("setLeague", vm.leagues);

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;
            vm.jQuery.each(vm.leagues, function (k, v) {
              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {
                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                  fx.push(vv);
                  vm.EventBus.$emit("event:leagues:show", v.competition_id);
                }
              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;
            });

            vm.competitions = vm.leagues;

            vm.getCompetitions();
          })
          .catch((err) => {
            vm.busy = false;

            vm.loading = "";

            if (err.response) {
              vm.setError("Failed", err.response.data.message);
            } else if (err.request) {
              // console.log(JSON.stringify(err.request));
            } else {
              // console.log(JSON.stringify(err));
            }
          });
    },

    getAlllive: function () {

      console.log('get getAlllive')
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';
      path = path.replace("{sport_id}", this.sport);
      console.log('path', path);

      var dat = {
        // page: vm.page,
        // per_page: vm.per_page,
        page:1,
        per_page:80,
        highlight_market_id: vm.market_id,
        // tournament_id:0,
        // category_id:0,
        // daily:0,
        // hours:0,
        match_live_status: 1,
        // today:0,
        // tomorrow:0,
        // upcoming:0,
        // boosted:0,
        order_type:'priority'
      };

      fix
          .get(path, { params: dat })
          .then((res) => {
            console.log("Live games", res)
            var games = res.data.data;
            vm.EventBus.$emit("total-games", res.data.total);
            vm.totals = res.data.total;

            // Transform data to desired format
            var formattedData = {
              status: 200,
              message: {
                data: [],
                from: games.from,
                last_page: games.last_page,
                remaining_records: games.remaining_records,
              }
            };

            // Loop through games and create new objects with required fields
            vm.jQuery.each(games, function (k, v) {

              var formattedGame = {
                tournament: v.tournament,
                sport_id: v.sport_id,
                game_id: v.game_id,
                name: v.name,
                match_id: v.match_id,
                date: v.date,
                total_markets: v.total_markets, // Placeholder value
                ActiveMarkets: v.active_markets, // Placeholder value
                competition_id: v.tournament_id ? v.tournament_id : "",
                status_code: v.status_code,
                status: v.status,
                live_coverage: 0, // Placeholder value
                market_name: v.highlight_market.market_name,
                country: v.country,
                country_code: "",
                event_time: v.event_time ? v.event_time : "",
                home_score: v.home_score,
                match_status: v.match_status,
                event_status: v.match_status === 'Not started' ? 'NotStarted' : '',
                away_score: v.away_score,
                home_team: v.home_team,
                away_team: v.away_team,
                outcomes: [],
              };

              // Process outcomes
              vm.jQuery.each(v.highlight_market.outcomes, function (kk, vv) {
                formattedGame.outcomes.push({
                  alias: vv.alias,
                  market_name: formattedGame.market_name,
                  outcome_name: vv.outcome_name,
                  specifier: v.highlight_market.specifier,
                  outcome_id: vv.outcome_id,
                  odd: vv.odds,
                  odds: vv.odds,
                  status: v.highlight_market.status,
                  active: vv.active,
                  producer_id: v.producer_id,
                  producer_status: v.producer_status,
                  market_id: v.highlight_market.market_id,
                });
              });

              formattedData.message.data.push(formattedGame);
            });

            vm.competitions = formattedData.message.data;

            vm.$store.dispatch("setLeague", formattedData);
            vm.is_busy = false;
            vm.visible_leagues = [];
          })
          .catch((err) => {
            // console.log("Problem", err)
            vm.busy = false;

            vm.loading = "";

            if (err.response) {
              vm.setError("Failed", err.response.data.message);
            } else if (err.request) {
              // console.log(JSON.stringify(err.request));
            } else {
              // console.log(JSON.stringify(err));
            }
          });
    },

    getSearch: function () {
      var vm = this;
      vm.leagues = [];
      vm.competitions = [];

      var path = process.env.VUE_APP_URL_SEARCH;

      path = path.replace("{sport_id}", 1);

      axios
          .post(path, JSON.stringify({search: vm.search}))
          .then((res) => {
            var games = res.data.message;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;
            vm.jQuery.each(vm.leagues, function (k, v) {
              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {
                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                  fx.push(vv);
                }
              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;
            });

            vm.competitions = vm.leagues;
          })
          .catch((err) => {
            vm.busy = false;

            vm.loading = "";

            if (err.response) {
              vm.setError("Failed", err.response.data.message);
            } else if (err.request) {
              // console.log(JSON.stringify(err.request));
            } else {
              // console.log(JSON.stringify(err));
            }
          });
    },

    getEsport: function () {
      var vm = this;

      var path = process.env.VUE_APP_URL_ESPORT_SPORT;

      path = path.replace("{sport_id}", 1);

      axios
          .post(path, JSON.stringify({}))
          .then((res) => {
            var games = res.data.message;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;

            vm.jQuery.each(vm.leagues, function (k, v) {
              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {
                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                  fx.push(vv);
                  vm.EventBus.$emit("event:leagues:show", v.competition_id);
                }
              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;
            });

            vm.competitions = vm.leagues;

            vm.getCompetitions();
          })
          .catch((err) => {
            vm.busy = false;

            vm.loading = "";

            if (err.response) {
              vm.setError("Failed", err.response.data.message);
            } else if (err.request) {
              // console.log(JSON.stringify(err.request));
            } else {
              // console.log(JSON.stringify(err));
            }
          });
    },

    getLive: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';
      path = path.replace("{sport_id}", this.sport);

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        match_live_status: 1,
      };

      fix
          .get(path, { params: dat })
          .then((res) => {
            console.log("live data sports", res)
            var games = res.data.message;
            vm.leagues = games.competitions;

            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });

            vm.$store.dispatch("setLeague", vm.leagues);

            vm.is_busy = false;
            vm.visible_leagues = [];

            var results = games.data;

            vm.jQuery.each(vm.leagues, function (k, v) {
              // get fixtures
              var fx = [];

              vm.jQuery.each(results, function (kk, vv) {
                if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                  fx.push(vv);
                  vm.EventBus.$emit("event:leagues:show", v.competition_id);
                }
              });

              v.fixtures = fx;
              v.is_visible = true;
              vm.leagues[k] = v;
            });

            vm.competitions = vm.leagues;

            vm.getCompetitions();
          })
          .catch((err) => {
            // console.log("This is why live games", err)
            vm.busy = false;

            vm.loading = "";

            if (err.response) {
              vm.setError("Failed", err.response.data.message);
              // console.log(JSON.stringify(err.response.data.message));
            } else if (err.request) {
              // console.log(JSON.stringify(err.request));
            } else {
              // console.log(JSON.stringify(err));
            }
          });
    },

    getGames: function () {
      var vm = this;

      var c = 10;
      var b = 0;

      var competitions = [];

      this.jQuery.each(vm.leagues, function (k, v) {
        b++;
        var competition_id = v.competition_id;
        // get fixtures

        if (b < c && v.fixtures.length === 0) {
          competitions.push(competition_id);
        }
      });

      vm.getFixture(competitions.join(","));
    },

    reloadUI: function () {
      this.autoRefreshUI(this.$vnode.tag);
    },
  },
  computed: {
    loadingDistance: function () {
      return 50;
    },
    market_outcome: function () {
      return this.market_outcome_headers;
    },
    games: function () {
      return this.$store.state.games;
    },
    allLoaded: function () {
      return (
          this.$store.state.games.current_page > 0 &&
          this.$store.state.games.current_page ===
          this.$store.state.games.last_page
      );
    },
    busy1: function () {
      return this.$store.state.busy;
    },
    sport_id: function () {

      return this.$store.state.sport_id;
    },
    market_id: function () {
      return this.$store.state.market_id;
    },
    hour: function () {
      return this.$store.state.hour;
    },
    profile: function () {
      return this.getProfile();
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
  },
  components: {
    // Highlight,
    Competition,
    // Waiting
  },
  data: function () {
    return {
      leagues: [],
      competitions: [],
      highlightsData: [],
      loading: [],
      visible: [],
      busy: [],
      is_busy: false,
      producerId: [],
      visible_leagues: [],
      mqttClient: false,
      market_outcome_headers: [],
      fixtures: [],
      totals: 0
    };
  },
  props: {
    date: {
      required: false,
      default: "",
    },
    searchable: {
      required: false,
      default: false,
      type: Boolean,
    },
    search: {
      required: false,
      default: "",
      type: String,
    },
    upcoming: {
      required: false,
      default: false,
    },
    highlights: {
      required: false,
      default: false,
    },
    live: {
      required: false,
      default: true,
    },
    today: {
      required: false,
      default: false,
    },
    leo: {
      required: false,
      default: true,
    },
    sport: {
      required: false,
      default: -1,
    },
    esport: {
      required: false,
      default: false,
    },
  },
  watch: {
    sport_id: function (newValue, oldValue) {
      /*console.log(
          "sport_id newValue ==> " + newValue + " oldValue ==> " + oldValue
      );*/

      if (parseInt(oldValue) > 0) {
        this.is_busy = true;
        if (this.highlights) {
          this.getHighlight();
        } else {
          this.competitions = [];
          //this.getCompetitions();
          this.getAlllive();
        }

        // this.initMqtt(this.sport_id());
      }
    },
    market_id: function () {
      this.competitions = [];
      this.is_busy = true;
      this.initMqtt();

      if (this.highlights) {
        this.getHighlight();
      } else {
        if (this.visible_leagues.length > 0) {
          this.getFixture(this.visible_leagues.join(","));
        }
      }
    },
    hour: function (newValue, oldValue) {
      console.log("Hour =>newValue ==> " + newValue + " oldValue ==> " + oldValue);
      this.is_busy = true;
      this.getCompetitions();
    },
    search: function (newValue, oldValue) {
      console.log(
          "search =>newValue ==> " + newValue + " oldValue ==> " + oldValue
      );

      if (newValue !== oldValue && newValue.length > 0) {
        this.is_busy = true;
        this.getSearch();
      }
    },
    date: function (newValue, oldValue) {
      console.log(
          "date newValue ==> " + newValue + " oldValue ==> " + oldValue
      );

      //if(parseInt(newValue) > 0 ) {
      this.is_busy = true;
      this.getCompetitions();
      this.initMqtt();

      //}
    },
  },
  beforeDestroy: function () {
    // console.log("beforeDestroy");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
  destroyed: function () {
    // console.log("destroyed");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
};
</script>