<template>

  <div>

    <div class="markets-header mr-1 feature-stick">

      <section class="header-markets d-flex justify-content-left">
      </section>

      <ShimmerMarkets v-show="loading"></ShimmerMarkets>

     <div class="row m-0 p-0">

       <div class="col-2 p-0 m-0">
        <div style="white-space: nowrap">
          <a href="#" class="custom-link text-light back-btn" @click="goBack"><i class="bi bi-chevron-left"></i> Back</a>
        </div>
       </div>

       <div class="col-12 p-0 m-0">
        <div class="text-blue text-center mb-0 league-text  opacity-85 font-14">
          Formula One
        </div>
       </div>
     </div>
    </div>

    <div class="row mr-0 ml-0 d-none" style="background-color: white; color: white">
      <div class="widgets col-12">
        <div><div class="sr-widget sr-widget-1"></div></div>
      </div>
    </div>

    <div class="" style="margin-bottom: 80px">

    <Outcome
        v-for="(m,index) in visibleMarkets"
        :key="getKey(index)"
        :market_id="m.market_id"
        :market_name="m.market_name"
        :fixture="fixture"
        :totals_18="totals_18"
        :totals_238="totals_238"
        :totals_166="totals_166"
        :outcome="m"
        :live="live" />

    </div>

    <BottomNavigation></BottomNavigation>

  </div>

</template>

<script>

import Outcome from './Outcome'
const BottomNavigation = () => import('./BottomNavigation.vue')
import ShimmerMarkets from '../components/shimmer/ShimmerMarkets.vue'
import axios from "@/services/api";
import mqtt from "mqtt";

(function(a,b,c,d,e,f,g,h,i){a[e]||(i=a[e]=function(){(a[e].q=a[e].q||[]).push(arguments)},i.l=1*new Date,i.o=f,
        g=b.createElement(c),h=b.getElementsByTagName(c)[0],g.async=1,g.src=d,g.setAttribute("n",e),h.parentNode.insertBefore(g,h)
)})(window,document,"script", "https://widgets.sir.sportradar.com/b8d7f097bc87d1cefd101f9f00a561c1/widgetloader", "SIR", {
  theme: false, // using custom theme
  language: "en"
});

export default {
  name: 'FormulaOne',
  components: {
    Outcome,
    BottomNavigation,
    ShimmerMarkets
  },
  data: function () {
    return {
      iconSize: 24,
      match_id: 0,
      game_id: 0,
      //fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0
      },
      //totals_18: [],
      //totals_238: [],
      live: false,
      seconds: 0,
      mqttClient: false,
      loading: true,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {

        // react to route changes...
        document.title = to.params.name.replace(/[^a-z0-9+]+/gi, " ");
        document.description = "The best boosted odds for " + to.params.name.replace(/[^a-z0-9+]+/gi, " ") + " get free bonus, freebets and many cashback offers ";
        this.game_id = to.params.game_id;//this.baseConverter(to.params.game_id, 36, 10);
        this.live = to.params.status === 'live';
        this.getMatch();
      }
    },
  },
  mounted: function () {

    // this.autoRefreshUI(this.$vnode.tag);
    this.$store.dispatch("setCurrentPage","match");
    window.scrollTo(0, 0);
    
  },
  computed: {
    marketGroups: function () {

      return this.$store.state.market_groups
    },
    visibleMarkets: function () {

      //return this.fixture.market;
      return this.$store.state.fixture.market;

    },
    fixture: function () {

      //return this.fixture.market;
      return this.$store.state.fixture;

    },
    totals_18: function () {

      //return this.fixture.market;
      return this.$store.state.fixture.totals_18;

    },
    totals_238: function () {

      //return this.fixture.market;
      return this.$store.state.fixture.totals_238;

    },
    totals_166: function () {

      //return this.fixture.market;
      return this.$store.state.fixture.totals_166;

    },
    collapsibleMarkets: function () {

      var max = 9;
      var x = 0;
      var markets = [];

      this.jQuery.each(this.$store.state.fixture.market,function(k,v){

        x++;

        if(x > max ) {

          markets.push(v);
        }

      });

      return [];
    },
    home_team: function () {

      return this.getHomeCompetitorName(this.$store.state.fixture.name)
    },
    away_team: function () {

      return this.getAwayCompetitorName(this.$store.state.fixture.name)
    },
    event_time: function () {

      var minutes = parseInt(this.$store.state.fixture.seconds / 60);
      //var seconds = this.seconds % 60;

      var min = minutes;

      if(minutes < 10 ) {

        min = "0"+min;
      }

      return min+"'";

    },
    profile: function () {

      return this.getProfile();

    },
  },
  methods: {
    isLive: function(){
      if(this.live){
        return 'text-danger font-weight-600';
      }else{
        return 'text-light';
      }
    },
    registerPoll: function(){

      var topic_betstop = 'topic/producer-3/sport-'+this.fixture.sport_id+'/status/match-'+this.fixture.match_id+'/#';

      if(this.live) {

        topic_betstop = 'topic/producer-1/sport-'+this.fixture.sport_id+'/status/match-'+this.fixture.match_id+'/#';
      }

      this.poll(topic_betstop);

    },

    initMqtt: function(){

      var endpoint = process.env.VUE_APP_URL_MQTT_HOST;

      var vm = this;

      if(this.mqttClient !== false ) {

        this.mqttClient.end();

      }

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      }

      var topic_match = 'topic/producer-3/sport-'+this.fixture.sport_id+'/status/match-'+this.fixture.match_id

      if(this.live) {
        topic_match = 'topic/producer-1/sport-'+this.fixture.sport_id+'/status/match-'+this.fixture.match_id
      }

      var client  = mqtt.connect(endpoint,options);

      client.on('connect', function () {

        /*
        client.subscribe(topic_betstop, function (err) {
          if (!err) {

            console.log('subscribed to topic '+topic_betstop);

          }
        })

        client.subscribe(topic_others, function (err) {
          if (!err) {

            console.log('subscribed to topic '+topic_others);

          }
        })
        */

        client.subscribe(topic_match, function (err) {
          if (!err) {

            console.log('subscribed to topic '+topic_match);

          }
        })

        var topic2 = 'topic/producer';
        client.subscribe(topic2, function (err) {
          if (!err) {

            console.log('subscribed to topic '+topic2);

          }
        })
      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      })

      this.mqttClient = client;

    },

    initMqtt1: function(){

      var vm = this;

      if(this.mqttClient !== false ) {

        this.mqttClient.end();

      }

      // subcribe to all sportID but specific matche for all markets for odds changes
      //var topic = 'topic/producer-3/+/match-'+this.fixture.match_id+'/#';
      var topic = 'topic/producer-3/sport-'+this.fixture.sport_id+'/match-'+this.fixture.match_id+'/#';

      if(this.live) {

        topic = 'topic/producer-1/sport-'+this.fixture.sport_id+'/match-'+this.fixture.match_id+'/#';

      }

      var client  = mqtt.connect(process.env.VUE_APP_URL_MQTT_HOST);

      client.on('connect', function () {
        client.subscribe(topic, function (err) {
          if (!err) {

            console.log('subscribed to topic '+topic);

          }
        })
      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.oddsUpdate(payload);
      })

      this.mqttClient = client;

    },

    listenForOddsChange: function(){

      var vm = this;

      vm.EventBus.$on('match:reload:'+this.fixture.match_id,function(updates){

        vm.updateFixture(updates);

      });

      /*
      vm.EventBus.$on('match:update:'+this.fixture.match_id,function(updates){

        vm.updateMatch(updates);

      });
       */

    },

    getKey: function(index){

      return Math.random().toString(10).replace('0.', 'outcomes-id-' +index+ '-');

    },
    getColumnWidth: function(a){

      if (a.length === 2 ) {

        return 'col-6';
      }

      if (a.length === 3 ) {

        return 'col-4';
      }

      return  'col-auto'

    },
    getHomeCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }

      return matchName.split('vs.')[0];
    },
    getAwayCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }
      return matchName.split('vs.')[1];
    },
    getMatch: function () {
      this.loading = true;
      // var vm = this;
      var path = process.env.VUE_APP_URL_MATCH_ODD_PREMATCH;
      path = process.env.VUE_APP_URL_MORE_MARKETS_URL;
      path = path.replace("{game_id}", this.game_id);

      axios.post(path, JSON.stringify({game_id: this.game_id}))
          .then(res => {

            var fixture = res.data.message;
            console.log(fixture);
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            console.log(error)

          })
    },
    getOddDirectionClass: function (outcome) {

      if(parseInt(outcome.active) === 0 || parseInt(outcome.status) != 1) {

        return '';
      }

      if(parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds) ) {

        return 'arrow green up';

      }

      return 'arrow red down';

    },

  },
  beforeDestroy: function () {
    console.log("beforeDestroy")

    if(this.mqttClient !== false ) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    
    if(this.mqttClient !== false ) {

      this.mqttClient.end();
      this.mqttClient = false;

    }
  }
}
</script>