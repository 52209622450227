var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"game-card px-2 mb-1"},[_c('div',{staticClass:"row p-0 m-0 small-text mb-0 opacity-8"},[_c('div',{staticClass:"col-9 p-0 m-0",staticStyle:{"color":"#666666"}},[_vm._v(" "+_vm._s(_vm.currentFixture.country)+" • "+_vm._s(_vm.shortenName(_vm.currentFixture.tournament, 40))+" ")]),_c('div',{staticClass:"col-3 p-0 m-0 d-none",staticStyle:{"color":"#666666"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.live),expression:"!live"}]},[_vm._v(_vm._s(_vm.formatMatchDate(_vm.currentFixture.date)))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.live),expression:"!live"}],staticClass:"col-3 p-0 m-0 text-right",staticStyle:{"color":"#666666"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.live),expression:"!live"}]},[_vm._v(_vm._s(_vm.formatMatchDate(_vm.currentFixture.date)))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.live),expression:"live"}],staticClass:"col-3 p-0 m-0 text-right",staticStyle:{"color":"#666666"}},[_vm._v(" "+_vm._s(_vm.currentFixture.match_status)+" "),_c('span',{staticClass:"text-yellow pl-1"},[_vm._v(" "+_vm._s(_vm.currentFixture.event_time)+" ")])])]),_c('div',{staticClass:"row p-1 m-0 games"},[_c('div',{staticClass:"col-4 px-0 text-light game-card-teams pt-0"},[_c('router-link',{staticClass:"text-light d-block font-weight-bold",staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: 'match',
            params: {
              game_id:
                _vm.currentFixture.game_id === undefined
                  ? 0
                  : _vm.currentFixture.game_id,
              name: _vm.toURL(_vm.currentFixture.name),
              status: _vm.live ? 'live' : 'prematch',
            },
          }}},[_c('div',{staticClass:"mb-1",staticStyle:{"color":"black","font-weight":"normal"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.live),expression:"live"}],staticClass:"score",domProps:{"textContent":_vm._s(_vm.currentFixture.home_score)}}),_vm._v(" "+_vm._s(_vm.shortenName(_vm.home_team, 20))+" ")]),_c('div',{staticClass:"mb-0",staticStyle:{"color":"black","font-weight":"normal"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.live),expression:"live"}],staticClass:"score",domProps:{"textContent":_vm._s(_vm.currentFixture.away_score)}}),_vm._v(" "+_vm._s(_vm.shortenName(_vm.away_team, 20))+" ")]),_c('div',{staticClass:"odd-indicator pt-0 font-weight-bold marketee skyBlue-text opacity-8",staticStyle:{"color":"rgb(102, 102, 102)","font-size":"8px","font-weight":"normal","padding-top":"0.5em !important"}},[_vm._v(" +"+_vm._s(_vm.currentFixture.total_markets)+" "),_c('span',{},[_vm._v(" markets")])])])],1),_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"row",staticStyle:{"align-content":"center"}},_vm._l((_vm.currentFixture.outcomes),function(o){return _c('Odd',{key:_vm.getKey(o.odd_id),class:_vm.getClasses(o, _vm.currentFixture.outcomes),staticStyle:{"margin":"0px !important","padding":"2px !important","height":"fit-content"},attrs:{"id":_vm.getOddID(
                _vm.currentFixture.match_id,
                o.market_id,
                o.outcome_id,
                o.specifier,
                'odd'
              ),"sport_id":_vm.currentFixture.sport_id,"match_id":_vm.currentFixture.match_id,"market_name":o.market_name,"home_team":_vm.home_team,"away_team":_vm.away_team,"market_id":o.market_id,"outcome_id":o.outcome_id,"specifier":o.specifier,"active":o.active,"status":o.status,"odds":o.odd,"producer_id":o.producer_id,"producer_status":o.producer_status,"previous_odds":o.previous_odds,"alias":o.alias,"probability":o.probability,"pick":o,"live":_vm.live}})}),1)]),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-1 px-0 pt-4 text-center",staticStyle:{"display":"none"}},[_c('img',{staticClass:"img-src",attrs:{"src":"img/icon/chart.png","height":"10","width":"10"}}),_c('br')])
}]

export { render, staticRenderFns }