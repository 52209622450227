var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.statusClass,attrs:{"disabled":_vm.locked}},[_c('button',{staticClass:"btn odd-btn small-txt",class:_vm.picked,staticStyle:{"width":"100%","text-align":"center","height":"fit-content","color":"#000000c7","font-size":"10px !important","font-weight":"700 !important","border-radius":"5px"},attrs:{"id":_vm.getOddID(_vm.match_id,_vm.market_id,_vm.outcome_id,_vm.specifier,_vm.boosted ? 'boosted' : 'odd'),"oddStatus":_vm.oddStatus,"oddValue":_vm.odd,"oddActive":_vm.oddActive,"producerId":_vm.producer_id,"producerStatus":_vm.producerStatus},on:{"click":function($event){return _vm.addPick(
            _vm.sport_id,
            _vm.match_id,
            _vm.market_name,
            _vm.market_id,
            _vm.home_team,
            _vm.away_team,
            _vm.producer_id,
            _vm.specifier,
            _vm.pick,
            _vm.status
          )}}},[_c('div',{staticClass:"grey-text text-center odd-indicator"},[_c('span',{staticClass:"odd-direction arrow green up",class:_vm.directionClass,staticStyle:{"width":"0.5em","height":"0.5em","margin":"0.1em"}}),_c('span',{domProps:{"textContent":_vm._s(_vm.alias)}})]),(_vm.locked)?_c('span'):_c('span',{staticClass:"odd-number"},[_vm._v(_vm._s(_vm.odd))]),(_vm.locked)?_c('span',{staticStyle:{"height":"1.5em"}},[_c('LockIcon',{attrs:{"size":_vm.iconSize,"fill-color":"white"}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }