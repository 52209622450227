<template>
  <div class="body">
    <div id="app">
      <section
        class="top-header topHeader"
        v-bind:class="canStick()"
        v-if="current_page != 'print-game'"
        v-show="current_page != 'casinogames'"
      >
        <router-link
          to="/deposit"
          class="deposit-link"
          v-show="
            current_page != 'deposit' &&
            current_page != 'casinogames' &&
            current_page != 'affiliates'
          "
        >
          <div class="row deposit">
            <span style="font-weight: 600"
              >Click for FREE DEPOSIT -> M-PESA Paybill: 222563</span
            >
          </div>
        </router-link>

        <div
          class="d-flex justify-content-between deposit_page"
          v-if="
            current_page == 'deposit' &&
            current_page != 'casinogames' &&
            current_page != 'affiliates'
          "
        >
          <div class="d-flex justify-around align-center text-dark">
            <div class="s-backButton btn" @click="goBack">
              <i class="bi bi-chevron-left"></i> Back
            </div>
          </div>
          <div class="d-flex justify-around">
            <div
              @click="goTo('deposit-how-to')"
              class="mr-3 text-dark"
              style="font-size: 13px"
            >
              <i class="bi bi-question-circle d-none"></i>How Do I Deposit
            </div>
            <router-link to="/"
              ><i style="color: black" class="bi bi-house-door-fill"></i
            ></router-link>
          </div>
        </div>

        <div
          class="row p-0 m-0"
          style="background-color: var(--white)"
          v-show="
            current_page != 'deposit' &&
            current_page != 'casinogames' &&
            current_page != 'affiliates'
          "
        >
          <div class="col-3 p-0 m-0">
            <div class="d-flex justify-content-left">
              <!-- <div class="icon-item-wrapper text-center">
                <a @click="showMenu">
                  <i class="bi bi-list text-dark"></i> <br>
                  <span class="white-txt txt">Menu</span>
                </a>
              </div> -->

              <div class="icon-item-wrapper text-center">
                <a @click="goTo('search')">
                  <i class="bi bi-search text-dark"></i> <br />
                  <span class="white-txt txt">Search</span>
                </a>
              </div>

              <div class="icon-item-wrapper text-center d-none">
                <a @click="goTo('print')">
                  <i class="bi bi-printer text-dark"></i> <br />
                  <span class="white-txt txt">Print</span>
                </a>
              </div>
            </div>
          </div>

          <div class="col-5 px-0 pt-1 pb-1 m-0 text-center logo">
            <router-link to="/">
              <img
                style="width: 80px"
                src="/img/ponyokaBetLogo.png"
                class="top-header-logo img-fluid"
                alt="Ponyoka Logo"
              />
            </router-link>
          </div>

          <div class="col-4 p-0 m-0 login">
            <div
              v-if="profile"
              class="d-flex depo text-center justify-content-around"
            >
              <router-link to="/gifts">
                <img src="/img/icon/gift.png" class="wallet-icon" /> <br />
                <span style="color: var(--red)" class="bet-history wallet-text">
                  <strong>Ksh{{ formatCurrency(myProfile.b5) }} </strong>
                </span>
              </router-link>
              <router-link to="/my-account">
                <img src="/img/icon/money.png" class="wallet-icon" /> <br />
                <span
                  style="color: var(--red)"
                  class="wallet-balance wallet-text"
                >
                  <strong>Ksh<span v-text="bal"></span></strong>
                </span>
              </router-link>
            </div>

            <div v-else class="d-flex text-center pr-2 pt-1 d-none">
              <router-link
                to="/login"
                class="header-login-button small-text mr-1"
              >
                Login
              </router-link>

              <router-link
                to="/join"
                class="header-join-button darkBackground small-text"
                style="color: #fff !important"
              >
                Register
              </router-link>
            </div>
          </div>
        </div>
      </section>

      <section
        v-show="
          current_page != 'deposit' &&
          current_page != 'casinogames' &&
          current_page != 'affiliates'
        "
        style="position: relative"
        class="feature-menu p1"
      >
        <div
          class="icons-wrapper top-menu-items d-flex justify-content-between"
        >
          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('sport')"
          >
            <router-link to="/">
              <img src="/menu/sport.png" class="menu-icon-svg" /> <br />
              <span class="text-light txt">Home</span>
            </router-link>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('crash')"
          >
            <router-link to="crash-games">
              <img src="/menu/cards.png" class="menu-icon-svg" /> <br />
              <span class="text-light txt">Crash Games</span>
            </router-link>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('live')"
          >
            <a @click="goTo('live')">
              <img src="/menu/live-now.png" class="menu-icon-svg" /> <br />
              <span class="text-light txt">Live</span>
            </a>
          </div>

          <div
            style="display: none"
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('virtuals')"
          >
            <router-link to="/virtuals">
              <img src="/img/menu/offers.png" class="menu-icon-svg" />
              <sup><span class="custom-badge">NEW</span></sup
              ><br />
              <span class="text-light txt">Virtuals</span>
            </router-link>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('casino')"
          >
            <router-link to="/casino">
              <img src="/img/casino.png" class="menu-icon-svg" />
              <sup><span class="custom-badge">NEW</span></sup
              ><br />
              <span class="text-light txt">Casino</span>
            </router-link>
          </div>

          <div class="feature-icon-item-wrapper text-center">
            <a href="/mobile-app">
              <img src="/menu/mobile-app.png" class="menu-icon-svg" /> <br />
              <span class="text-light txt">App</span>
            </a>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('Soccer')"
          >
            <a @click="setSportMenu(1, 'Soccer')">
              <img src="/menu/ball.png" class="menu-icon-sports-svg" /> <br />
              <span class="text-light txt">Soccer</span>
            </a>
          </div>
          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('Basketball')"
          >
            <a @click="setSportMenu(2, 'Basketball')">
              <img src="/menu/basket-ball.png" class="menu-icon-sports-svg" />
              <br />
              <span class="text-light txt">Basketball</span>
            </a>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('Basketball')"
          >
            <a @click="setSportMenu(21, 'Cricket')">
              <img
                src="../public/menu/cricket-new.png"
                class="menu-icon-sports-svg"
              />
              <br />
              <span class="text-light txt">Cricket</span>
            </a>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('Basketball')"
          >
            <a @click="setSportMenu(5, 'Tennis')">
              <img
                src="../public/menu/tennis-new.png"
                class="menu-icon-sports-svg"
              />
              <br />
              <span class="text-light txt">Tennis</span>
            </a>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('Basketball')"
          >
            <a @click="setSportMenu(4, 'IceHockey')">
              <img src="/menu/IceHockey.png" class="menu-icon-sports-svg" />
              <br />
              <span class="text-light txt">Ice Hockey</span>
            </a>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('Basketball')"
          >
            <a @click="setSportMenu(16, 'AmericanFootball')">
              <img
                src="/menu/AmericanFootball.png"
                class="menu-icon-sports-svg"
              />
              <br />
              <span class="text-light txt">American Football</span>
            </a>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('Basketball')"
          >
            <a @click="setSportMenu(10, 'Boxing')">
              <img
                src="../public/menu/boxing-new.png"
                class="menu-icon-sports-svg"
              />
              <br />
              <span class="text-light txt">Boxing</span>
            </a>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('Basketball')"
          >
            <a @click="setSportMenu(31, 'Badminton')">
              <img
                src="../public/menu/badminton-new.png"
                class="menu-icon-sports-svg"
              />
              <br />
              <span class="text-light txt">Badminton</span>
            </a>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('Basketball')"
          >
            <a @click="setSportMenu(12, 'Rugby')">
              <img
                src="../public/menu/rugby-new.png"
                class="menu-icon-sports-svg"
              />
              <br />
              <span class="text-light txt">Rugby</span>
            </a>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('Basketball')"
          >
            <a @click="setSportMenu(23, 'Volleyball')">
              <img
                src="../public/menu/volley-ball-new.png"
                class="menu-icon-sports-svg"
              />
              <br />
              <span class="text-light txt">Volleyball</span>
            </a>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('Basketball')"
          >
            <a @click="setSportMenu(6, 'Handball')">
              <img
                src="../public/menu/handball-new.png"
                class="menu-icon-sports-svg"
              />
              <br />
              <span class="text-light txt">Handball</span>
            </a>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('Basketball')"
          >
            <a @click="setSportMenu(117, 'MixedMartialArts')">
              <img src="/menu/Mma.png" class="menu-icon-sports-svg" /> <br />
              <span class="text-light txt">Mixed Martial Arts</span>
            </a>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('Basketball')"
          >
            <a @click="setSportMenu(22, 'Darts')">
              <img src="/menu/Darts.png" class="menu-icon-sports-svg" /> <br />
              <span class="text-light txt">Darts</span>
            </a>
          </div>
        </div>
      </section>

      <!--A-Z Menu-->
      <div id="menu-modal" class="faso-menu-modal" style="z-index: 99999">
        <!-- Modal content -->
        <div class="faso-menu-modal-content">
          <section class="w-100 menu-bg">
            <div class="row m-0 p-0 side-menu-header">
              <div class="col-4 m-0 p-0">
                <div class="p-3 px-3">
                  <strong class="text-dark faso-menu-close">Menu</strong>
                </div>
              </div>
              <div class="col-4 text-center">
                <router-link to="/">
                  <img
                    style="width: 70px"
                    src="/img/ponyokaBetLogo.png"
                    class="top-header-logo2 pt-3"
                    @click="closeMenu"
                  />
                </router-link>
              </div>
              <div class="col-4 text-right m-0 p-0">
                <div class="p-3 faso-menu-close">
                  <router-link to="/">
                    <strong class="text-dark" id="menu-close" @click="closeMenu"
                      ><i class="bi bi-chevron-left"></i>
                      Back
                    </strong>
                  </router-link>
                </div>
              </div>
            </div>

            <hr style="background-color: var(--grey)" class="m-1" />
            <div
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <router-link to="print">
                <a class="flat-link" @click="closeMenu">
                  <img src="/menu/modal/print.png" class="side-icon-svg mr-2" />
                  <span class="text-dark faso-menu-close">
                    Print Odds & Matches
                  </span>
                </a>
              </router-link>
            </div>

            <div
              style="border-bottom: solid black 1px"
              class="justify-content-left px-3 py-1 flatlink-div"
            >
              <a @click="goTo('offers')">
                <a class="flat-link">
                  <img src="/menu/modal/promo.png" class="side-icon-svg mr-2" />
                  <span class="text-dark faso-menu-close"
                    >Offers And Promotions</span
                  >
                </a>
              </a>
            </div>

            <div
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <a @click="goTo('casino')">
                <a class="flat-link">
                  <img
                    src="/menu/modal/casino.png"
                    class="side-icon-svg mr-2"
                  />
                  <span class="text-dark">Casino</span>
                </a>
              </a>
            </div>

            <div
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <a @click="goTo('virtuals')">
                <a class="flat-link">
                  <img
                    src="/menu/modal/stream.png"
                    class="side-icon-svg mr-2"
                  />
                  <span class="text-dark">Virtuals</span>
                </a>
              </a>
            </div>

            <div
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <a @click="goTo('jackpot')">
                <a class="flat-link">
                  <img
                    src="/menu/modal/jackpot.png"
                    class="side-icon-svg mr-2"
                  />
                  <span class="text-dark">Jackpots</span>
                </a>
              </a>
            </div>

            <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <strong class="text-dark">Player Account</strong>
            </div>
            <div
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <a @click="goTo('bets')">
                <a class="flat-link">
                  <img src="/menu/modal/history.png" class="wallet-icon mr-2" />
                  <span class="text-dark">Bet History</span>
                </a>
              </a>
            </div>

            <div
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <a @click="goTo('profile')">
                <a class="flat-link">
                  <img src="/menu/modal/user1.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">My Profile</span>
                </a>
              </a>
            </div>

            <div
              v-if="showAffiliateDashboard"
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <a @click="goTo('affiliates')">
                <a class="flat-link">
                  <img src="/menu/modal/share.png" class="wallet-icon mr-2" />
                  <span class="text-dark">Affiliates Dashboard</span>
                </a>
              </a>
            </div>
            <div
              v-else
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <a @click="goTo('referral')">
                <a class="flat-link">
                  <img src="/menu/modal/share.png" class="wallet-icon mr-2" />
                  <span class="text-dark">Join Affiliates</span>
                </a>
              </a>
            </div>
            <div
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <a @click="goTo('my-account')">
                <a class="flat-link">
                  <img src="/menu/modal/wallet.png" class="wallet-icon mr-2" />
                  <span class="text-dark">My Account</span>
                </a>
              </a>
            </div>

            <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <strong class="text-dark">Help</strong>
            </div>
            <div
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <router-link to="/responsible-gaming" class="flat-link">
                <img
                  src="/menu/modal/18.png"
                  class="side-icon-svg mr-2"
                  @click="closeMenu"
                />
                <span class="text-dark" @click="closeMenu"
                  >Responsible Gaming</span
                >
              </router-link>
            </div>
            <div
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <router-link to="/bonus-policy" class="flat-link">
                <img
                  src="/menu/modal/18.png"
                  class="side-icon-svg mr-2"
                  @click="closeMenu"
                />
                <span class="text-dark" @click="closeMenu"
                  >Bonus Terms and Conditions</span
                >
              </router-link>
            </div>
            <div
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <router-link to="/terms-conditions-affiliates" class="flat-link">
                <img
                  src="/menu/modal/18.png"
                  class="side-icon-svg mr-2"
                  @click="closeMenu"
                />
                <span class="text-dark" @click="closeMenu"
                  >Terms and Conditions on Affiliates</span
                >
              </router-link>
            </div>

            <div
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <a @click="goTo('faq')">
                <a class="flat-link">
                  <img src="/menu/modal/faq.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">Frequently Asked Questions</span>
                </a>
              </a>
            </div>

            <div
              style="border-bottom: solid black 1px"
              class="d-flex justify-content-left px-3 py-1 flatlink-div"
            >
              <router-link to="/terms" class="flat-link">
                <img
                  src="/menu/modal/terms.png"
                  class="side-icon-svg mr-2"
                  @click="closeMenu"
                />
                <span class="text-dark" @click="closeMenu"
                  >Terms and Conditions</span
                >
              </router-link>
            </div>

            <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="logout" class="flat-link">
                <img src="/menu/modal/logout.png" class="side-icon-svg mr-2" />
                <span class="text-danger">Logout</span>
              </a>
            </div>
          </section>
        </div>
      </div>
      <!--End A-Z Menu-->

      <span id="menu-init" class="hidden"></span>
      <span class="faso-menu-close hidden"></span>

      <router-view></router-view>

      <div
        class="depo-widget d-none"
        id="dpo-widg"
        v-show="current_page != 'deposit'"
      >
        <span id="dpo-close" class="text-dark" @click="closeWidget()"
          ><i class="bi bi-x-lg"></i
        ></span>
        <router-link
          to="/deposit"
          class="deposit-link"
          v-show="current_page != 'deposit'"
        >
          <img src="/img/depo.webp" class="depo-widg" />
        </router-link>
      </div>

      <div
        id="footer"
        class="p-3"
        v-if="
          current_page != 'jackpot' &&
          current_page != 'casinogames' &&
          current_page != 'affiliates' &&
          !$route.meta.hideMenu
        "
      >
        <div class="first-row d-flex justify-content-around mb-4">
          <div class="text-center">
            <span class="title-text"> MPESA Paybill </span> <br />
            <span class="value-text"> 222563 </span>
          </div>
          <div class="text-center">
            <span class="title-text">
              <!--              SMS 'JOIN' to-->
              Call Us:
            </span>
            <br />
            <span class="value-text">
              <a style="color: white" target="_blank" href="tel:0757000333">
                {{ contacts.msisdn }}</a
              >
            </span>
          </div>
        </div>
        <!-- <div class="tct title-text text-center mb-2">
          <a style="background-color:#000;padding: 15px;">Payment Methods</a>
        </div> -->
        <div class="tct title-text text-center mb-2">Payment Methods</div>
        <div class="sec-row d-flex justify-content-center mb-4">
          <div class="imgg px-3">
            <img src="/img/twaweza_logo.png" alt="" />
          </div>
          <div class="imgg px-3">
            <img src="/img/mpesa.png" alt="" />
          </div>
        </div>

        <div class="disc disclaimer-text text-center mb-4">
          Ponyoka LTD, the provider of this website, is licensed by BCLB
          (Betting Control and Licensing Board of Kenya) for sports betting
          operations under the Betting, Lotteries and Gaming Act, Cap 131 Laws
          of Kenya under License Number: 0000797
        </div>
        <!-- <div
          class="disc disclaimer-text text-center mb-4"
          v-html="content"
        ></div> -->
        <div class="eitin text-center mb-4">
          <img src="/img/age-limit.png" alt="" />
        </div>
        <div class="term text-center disclaimer-text">
          <a href="/faq" class="footer-link disclaimer-text p-2"> FAQs </a>
          |
          <a href="/responsible-gaming" class="footer-link disclaimer-text p-2">
            Responsible Gaming
          </a>
          |
          <a href="/terms" class="footer-link disclaimer-text p-2">
            Terms and Conditions
          </a>
        </div>
        <hr />
        <span class="mx-auto d-flex justify-content-center">
          <!-- <span> <i class="bi bi-telephone mr-1"></i> </span> -->
          <!-- {{ contacts.msisdn }}<span>|</span> -->
          <span> <i class="bi bi-whatsapp mr-1"></i> </span>
          {{ contacts.whatsapp }}<span>|</span>
          <span class="ml-2"> <i class="bi bi-envelope mr-1"></i></span>
          {{ contacts.email }}
        </span>
        <hr />
        <div class="contact-social">
          <ul
            style="list-style: none"
            v-for="(social, index) in smh"
            :key="index"
          >
            <li>
              <a class="hover-target" :href="`${social.link}`">
                <img
                  class=""
                  style="height: 80%; width: 80%"
                  :src="getIconUrl(social.icon)"
                  alt=""
                />
                <!-- <i class="bi bi-facebook"></i> -->
              </a>
            </li>
          </ul>
          <!-- <a href="https://www.facebook.com/profile.php?id=61552225340960" class="footer-link title-text p-2">
            <i class="bi bi-facebook"></i>
          </a> -->
          <!-- <a href="https://x.com/Ponyoka_/" class="footer-link title-text p-2">
            <i class="bi bi-twitter"></i>
          </a> -->
          <!-- <a href="https://api.whatsapp.com/send?phone=0712767737" class="footer-link title-text p-2">
              <i class="bi bi-whatsapp"></i>
            </a> -->
          <!-- <a href="https://www.instagram.com/betcraft.africa?igsh=bDF3eW41MDByNmx2" class="footer-link title-text p-2">
            <i class="bi bi-instagram"></i>
          </a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mqtt from "mqtt";
import axios from "@/services/api";
import cms from "@/services/cms";

export default {
  name: "app",
  components: {},

  data: function () {
    return {
      current_menu: "",
      amount: 49,
      iconSize: 24,
      iconBigSize: 30,
      mqttClient: false,
      myProfile: this.getProfile(),
      loading: "",
      message: "Please enter amount you want to deposit",
      showAffiliateDashboard: false,
      content: "",
      smh: [],
      contacts: {},
    };
  },

  mounted: function () {

    this.getLicence();
    this.getContacts();
    this.getSmh();

    var vm = this;

    vm.myProfile = vm.getProfile();
    this.initMenuModal();
    this.getSports();
    this.fetchAffiliateStatus();
    setInterval(function () {
      vm.myProfile = vm.getProfile();
    }, 2000);


    this.EventBus.$on("deposit:popup", function (payload) {
      vm.amount = payload.amount;
      vm.message = payload.message;
      if (document.getElementById("deposit-init") != null) {
        document.getElementById("deposit-init").click();
      }
    });

    this.EventBus.$on("init:mqtt", function () {

      if (vm.mqttClient !== false) {
        vm.mqttClient.end();
        vm.mqttClient = false;
      }

      vm.myProfile = vm.getProfile();
      vm.initMqtt();
    });

    this.EventBus.$on("subscribe:websocket", function (payload) {

      var matchID = payload.matchID;
      vm.subscribeToMatchID(matchID)

    });

    vm.initMqtt();

    var params = this.getURLParameters();

    var utm_source = params.utm_source ? params.utm_source : "";
    var utm_medium = params.utm_medium ? params.utm_medium : "";
    var utm_campaign = params.utm_campaign ? params.utm_campaign : "";
    var referral_code = params.p ? params.p : "";
    var referrer = document.referrer;
    var btag = params.btag ? params.btag : "";
    console.log("GOT referrer " + referrer);

    if (btag.length > 0) {
      this.setValue("btag", btag);
    }

    if (utm_source.length > 0) {
      this.setValue("utm_source", utm_source);
    }

    if (utm_medium.length > 0) {
      this.setValue("utm_medium", utm_medium);
    }

    if (referral_code.length > 0) {
      this.setValue("referral_code", referral_code);
    }

    if (utm_campaign.length > 0) {
      this.setValue("utm_campaign", utm_campaign);
    }
    this.setValue("referrer", referrer);
  },

  computed: {
    showAppBanner: function () {
      if (this.$store.state.show_app_banner) {
        return "";
      } else {
        return "d-none";
      }
    },
    bal: function () {
      return this.formatCurrency(this.profile.b1 + this.profile.b3);
    },
    full_name: function () {
      if (this.profile === undefined) {
        return "";
      }

      if (this.profile.f1 === undefined || this.profile.f1.length === 0) {
        return ms;
      }

      var fn = this.profile.f1;
      var mn = this.profile.f2;
      var ln = this.profile.f3;
      var ms = this.getUIValue(this.profile.m);
      return fn + " " + mn + " " + ln + " - " + ms;
    },
    profile: function () {
      return this.myProfile;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {
        if (v.sport_id > 1) {
          t.push(v);
        }
      });

      return t;
    },
    homePageFontColor: function () {
      if (this.current_page === "home") {
        return "yellow-txt";
      }

      return "";
    },
    livePageFontColor: function () {
      if (this.current_page === "live") {
        return "yellow-txt";
      }

      return "";
    },
    historyPageFontColor: function () {
      if (this.current_page === "history") {
        return "yellow-txt";
      }

      return "";
    },
    betslip: function () {
      //var bSlip = this.getObject("betslip");
      //return bSlip;
      return this.$store.state.betslip;
    },
    activeBets: function () {
      var p = this.getProfile();

      if (!p) {
        return 0;
      }

      return p.b;
    },
    betslip_count: function () {
      if (!this.betslip.total || this.betslip.total == "") {
        return 0;
      }

      return parseInt(this.betslip.total);
    },
  },

  methods: {
    async getContacts() {
      try {
        const response = await cms.get("/get/conatcts");
        if (response.status == 200) {
          this.contacts = response.data;
        }
      } catch (error) {
        console.log("An error occurred while fetching contacts", error);
      }
    },
    getIconUrl(url) {
      if (url && !url.startsWith("https://")) {
        return `https://${url}`;
      }
      return url;
    },
    async getLicence() {
      try {
        const response = await cms.get("/get/license", {
          headers: {
            Accept: "application/json",
          },
        });
        if (response.status === 200) {
          this.content = response.data.content;
          console.log(response.data);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching responsible gamming",
          error.response?.data || error.message
        );
      }
    },
    async getSmh() {
      try {
        const response = await cms.get("/get/smhandles", {
          headers: {
            Accept: "application/json",
          },
        });
        if (response.status === 200) {
          this.smh = response.data.socialMedia;
          console.log(response.data);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching social media handles",
          error.response?.data || error.message
        );
      }
    },
    async fetchAffiliateStatus() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_AFFILIATE_BASE_URL}/whoami`,
          {
            headers: {
              "api-key": this.getAuth(),
            },
          }
        );

        if (response.status === 200) {
          this.showAffiliateDashboard = true;
          console.log("TRUE STATUS " + response.status);
        } else {
          this.showAffiliateDashboard = false;
          console.log("FALSE STATUS " + response.status);
        }
      } catch (error) {
        console.error("Error fetching affiliate status:", error);
        this.showAffiliateDashboard = false;
      }
    },
    closeMenu() {
      document.getElementById("menu-modal").style.display = "none";
    },
    canStick: function () {
      if (
        this.$store.state.current_page == "casinogames" ||
        this.$store.state.current_page == "virtuals"
      ) {
        return "";
      } else {
        return "header-stick";
      }
    },
    canFeatureStick: function () {
      if (
        this.$store.state.current_page == "match" ||
        this.$store.state.current_page == "casinogames" ||
        this.$store.state.current_page == "casino" ||
        this.$store.state.current_page == "casino-live" ||
        this.$store.state.current_page == "virtuals"
      ) {
        return "";
      } else {
        return "feature-stick";
      }
    },
    isActive: function (page) {
      return this.current_page === page ? "active" : "";
    },

    setActive: function (menu) {
      this.current_menu = menu;
    },

    livescore: function () {},

    initMqtt: function () {
      var endpoint = process.env.VUE_APP_URL_MQTT_HOST;
      var vm = this;

      if (this.mqttClient !== false) {
        this.mqttClient.end();
      }

      // connection option

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      };

      var client = mqtt.connect(endpoint, options);

      client.on("connect", function () {

        console.log("websockets connected successfully");

        if (vm.myProfile) {

          var profileTopic = "topic/profile/" + vm.myProfile.id;
          profileTopic = profileTopic.toLowerCase();

          client.subscribe(profileTopic, function (err) {
            if (!err) {

              console.log("subscribed to topic " + profileTopic);
            }
          });

        }

      });

      client.on("message", function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      });

      this.mqttClient = client;
    },

    getMarket: function (sport_id) {
      this.$store.dispatch("getMarkets", sport_id);
    },
    setSport: function (sport) {
      this.dismiss();
      //console.log('setSport ' + JSON.stringify(sport));

      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport.sport_id);
      this.goHome();
    },
    setSportMenu: function (sport_id, sport_name) {
      // this.dismiss();
      //console.log('setSport ' + JSON.stringify(sport));

      // this.sport = sport;
      this.sport_name = sport_name;
      // this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id);

      //if(this.$route.path !== "/"){

      this.$router.push({ name: "sport", params: {} });
      //}
    },

    initMenuModal: function () {
      // Get the modal
      var modal = document.getElementById("menu-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("menu-init");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("faso-menu-close")[0];

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
        document.body.style.position = "";
      };

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
        //document.body.style.position = 'fixed';
      };

      document.addEventListener("click", (e) => {
        if (e.target == document.querySelector("menu-modal")) {
          modal.style.display = "none";
        }
      });

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },

    initDeposit: function () {
      this.dismiss();
      this.$router.push({ name: "deposit" });
    },
    dismiss: function () {
      console.log("DISMISS MENU");
      var modal = document.getElementById("menu-modal");
      modal.style.display = "none";
    },

    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "sport-" + index + "-");
    },
    getSports: function () {
      this.$store.dispatch("getSports");
    },
    setAmount: function (amount) {
      this.amount = amount;
      this.deposit();
    },
    deposit: function () {
      this.reset();
      var p = this.getProfile();

      if (!p) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 1) {
        this.setError("Invalid Amount", "ENter amount atleast 1 KSH or above");
        return;
      }

      var vm = this;
      var path = process.env.VUE_APP_URL_DEPOSIT.replace("{profile_id}", p.d);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      var data = {
        amount: parseInt(this.amount),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
      };

      vm.loading = "loading";

      axios
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.message;
          vm.setSuccess("Deposit Initiated", msg);
          vm.message = "Please enter amount you want to deposit";
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.status) === 401 ||
              parseInt(err.response.status) === 400 ||
              parseInt(err.response.status) === 428
            ) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.message);
              console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            console.log(JSON.stringify(err.request));
          } else {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            console.log(JSON.stringify(err));
          }
        });
    },

    showDeposit: function () {
      document.getElementById("deposit-init").click();
    },
    searchVisible: function () {
      console.log("this.current_page ==> " + this.current_page);

      var searchVisible = this.current_page !== "search";
      console.log(
        "this.current_page ==> " +
          this.current_page +
          " searchVisible ==> " +
          searchVisible
      );

      return searchVisible;
    },

    subscribeToMatchID: function (matchID) {

      let vm = this;

      let gameStatus = ["prematch","live"]

      let topics = []

      vm.jQuery.each(gameStatus, function (k, v) {
        topics.push(`feeds/${v}/odds_change/${matchID}`)
      });

      vm.jQuery.each(topics, function (k, v) {

        vm.mqttClient.subscribe(v, function (err) {

          if (!err) {
            console.log("subscribed to | " + v);
          }

        });

      });

    }
  },

  beforeDestroy: function () {
    console.log("beforeDestroy");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
  destroyed: function () {
    console.log("destroyed");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
};
</script>
<style scoped>
.topHeader {
  z-index: 1000;
}
.contact-social {
  margin-top: 30px;
  /* margin-right: 10px; */
}

.contact-social > ul {
  display: inline-flex;
  margin: 0px;
}

.contact-social ul li a {
  /* border: 1px solid #8b9199; */
  color: #8b9199;
  display: inline-block;
  height: 40px;
  /* margin: 0 10px; */
  padding-top: 7px;
  transition: all 0.4s ease 0s;
  /* width: 40px; */
}

.contact-social ul li a:hover {
  border: 1px solid #fab702;
  color: var(--red);
}
</style>
